<template>
    <v-expansion-panel-content v-if="dataSource">
        <div class="table">
            <div class="item">{{ getTranslatedText('No') }}</div>
            <div v-if="!isMobile" class="item">{{ getTranslatedText('Selection') }}</div>
            <div class="item">{{ getTranslatedText('Game') }}</div>
            <div class="item">{{ getTranslatedText('Bet') }}</div>
            <div class="item">{{ getTranslatedText('Draw date') }}</div>
            <div v-if="!isMobile" class="item">{{ getTranslatedText('Results') }}</div>
            <div class="item">{{ getTranslatedText('Earnings') }}</div>
        </div>
        <div>
            <div class="table-content" v-for="(data, index) in dataSource.bets.entries" :key="index">
                <div class="item item1">{{ data.entry.betId }}</div>
                <div class="item item2">{{ data.game.name }}</div>
                <div class="item item3">{{ dataSource.transaction.currency + ' ' + renderPrice(data.entry.wager) }}</div>
                <div class="item item4">{{ renderDate(data.event.drawDate) }}</div>
                <div class="item item5">{{ dataSource.transaction.currency + ' ' + renderPrice(data.entry.winnings) }}</div>
                <div class="item item6 numbers-cell" v-if="!isMobile">
                    <div class="numbers-cell__content">
                        <span class="numbers" v-for="(value, i) in data.entry.bet" :key="i">
                            <span v-for="(v, k) in value" :key="k">{{ v }}</span>
                            <b v-if="i !== data.entry.bet.length - 1">+</b>
                        </span>
                    </div>
                </div>
                <div class="item item7 numbers-cell" v-if="!isMobile">
                    <div class="numbers-cell__content">
                        <span class="numbers" v-for="(val, idx) in data.event.results" :key="idx">
                            <span v-for="(v, k) in val" :key="k">{{ v }}</span>
                            <b v-if="idx !== data.event.results.length - 1">+</b>
                        </span>
                    </div>
                </div>
                <div class="block" v-if="isMobile">
                    <div class="item-block numbers-cell">
                        <b>{{ getTranslatedText('Selection') }}</b>
                        <div class="numbers-cell__content">
                            <span class="numbers" v-for="(value, i) in data.entry.bet" :key="i">
                                <span v-for="(v, k) in value" :key="k">{{ v }}</span>
                                <b v-if="i !== data.entry.bet.length - 1">+</b>
                            </span>
                        </div>
                    </div>
                    <div class="item-block numbers-cell">
                        <b>{{ getTranslatedText('Results') }}</b>
                        <div class="numbers-cell__content">
                            <span class="numbers" v-for="(val, idx) in data.event.results" :key="idx">
                                <span v-for="(v, k) in val" :key="k">{{ v }}</span>
                                <b v-if="idx !== data.event.results.length - 1">+</b>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <table class="raffle-table" v-if="dataSource.bets.raffles && dataSource.bets.raffles.length > 0">
            <tr v-for="(data, index) in dataSource.bets.raffles" :key="index">
                <td>{{ getTranslatedText('Raffle Id') }}: {{ data.promotion_id }}</td>
                <td>{{ getTranslatedText('Raffle Entry') }}: {{ data.entry_count }}</td>
                <td>{{ getTranslatedText('Raffle Status') }}: {{ getTranslatedText(data.status) }}</td>
                <td style="text-align: right;" v-if="data.winnings && data.winnings.length > 0">
                    {{ getTranslatedText('Raffle Winnings') }}<br>
                    <span>{{ getTranslatedText('total winnings') }}: {{ (data.currency ?? dataSource.transaction.currency) + ' ' + renderPrice(data.total_winnings) }}</span>
                    <span v-for="(d, i) in data.winnings" :key="i"><br>{{ getTranslatedText('Tier') }} {{ d.tier }}: 
                        {{ 
                            data.prize_type_slug === 'monetary' ? (data.currency ?? dataSource.transaction.currency) + ' ' + renderPrice(d.winnings) : d.winnings
                        }}
                    </span>
                </td>
                <td style="text-align: right;" v-else>{{ getTranslatedText('Raffle Winnings') }}: {{ (data.currency ?? dataSource.transaction.currency) }} 0</td>
            </tr>
        </table>
    </v-expansion-panel-content>
</template>

<script>
import languageMixins from '@/mixins/language/language-mixins'

import { formatPrice, formatDate, convertDate } from '@/utils/utils'

export default {
    mixins: [languageMixins],
    props: {
        dataSource: {
            type: Array,
            default: () => [],
        },
        isMobile: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {}
    },

    methods: {
        renderDate(date) {
            const dateFormat = formatDate(convertDate(date))
            let valueDate = `${dateFormat.year}-${dateFormat.month}-${dateFormat.day} ${dateFormat.hour24h}:${dateFormat.minutes}`
            return valueDate
        },

        renderPrice(price) {
            return formatPrice(price)
        },
    },
}
</script>
