<template>
    <div class="history">
        <div class="history-menu">
            <MenuRaffleResults />
        </div>
        <div v-if="!shouldShowActivePromotions" class="history-content">
            <RaffleResults />
        </div>
        <div v-if="shouldShowActivePromotions" class="history-content">
            <Promotion />
        </div>
    </div>
</template>

<script>
import RaffleResults from '@/components/raffle-results/index.vue'
import MenuRaffleResults from '@/components/raffle-results/menu.vue'
import Promotion from "@/components/promotion/index.vue"
import EventBus from '@/utils/EventBus'

export default {
    data() {
        return {
            shouldShowActivePromotions: false,
        }
    },
    components: {
        RaffleResults,
        MenuRaffleResults,
        Promotion
    },
    created() {
        EventBus.$on('show-active-promotions', (v) => this.showActivePromotions(v))
    },
    destroyed() {
        EventBus.$off('show-active-promotions')
    },
    methods: {
        showActivePromotions(v) {
            this.shouldShowActivePromotions = v
        },
    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/history/_index.scss';
</style>
