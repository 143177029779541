<template>
    <div class="main-betslip" :class="{ toggle: isToggle && $store.state.isScreenMobile }">
        <div class="betslip">
            <div class="betslip__empty" v-if="$store.state.cart.length === 0 && !$store.state.isScreenMobile">
                {{ getTranslatedText('cart empty') }}
            </div>
            <div class="betslip__body" v-else>
                <img class="icon-close-betslip" v-if="$store.state.isScreenMobile && isToggle" :src="require(`@/public/theme/${themePath}/img/common/close-white.svg`)" @error="$event.target.src = require(`@/public/theme/${themePath}/img/common/close-white.svg`)" @click="onToggleBetslip()" />
                <div class="betslip__header" @click="onToggleBetslip()">
                    <span class="betslip__header-title">{{ getTranslatedText('BETSLIP') }}</span>
                    <div class="betslip__header-quantity">{{ renderQuantity() }}</div>
                    <img v-if="$store.state.isScreenMobile && !isToggle" :src="require(`@/public/theme/${themePath}/img/common/arrow.png`)" @error="$event.target.src = require(`@/public/theme/${themePath}/img/common/arrow.svg`)" />
                </div>
                <div class="betslip__empty" v-if="$store.state.cart.length === 0 && $store.state.isScreenMobile && isToggle">
                    {{ getTranslatedText('cart empty') }}
                </div>
                <div v-else class="betslip__header-content" :class="{ toggle: isToggle && $store.state.isScreenMobile }">
                    <div class="betslip__header-tab" v-if="$store.state.isShow590 && $store.state.isShow645 && $store.state.isShow545 && $store.state.isShow459">
                        <template v-for="(v, i) in listMenugames">
                            <template v-if="(v.step === 1 && $store.state.isShow590) || (v.step === 2 && $store.state.isShow645) || (v.step === 3 && $store.state.isShow459) || (v.step === 4 && $store.state.isShow545)">
                                <div class="betslip__header-tab-item" :class="{ active: $store.state.stepCart === v.step }" :key="i" @click="() => onChangeTabBetslip(v.step)">
                                    {{ getTranslatedText(v.title) }}
                                    <div class="betslip__header-quantity">{{ renderQuantityCart(v.step) }}</div>
                                </div>
                            </template>
                        </template>
                    </div>
                    <div class="betslip__empty" v-if="$store.state.cartRender.length === 0">
                        {{ getTranslatedText('cart empty') }}
                    </div>
                    <template v-else>
                        <div class="betslip__list">
                            <div class="betslip__item" v-for="(value, index) in $store.state.cartRender" :key="index">
                                <div class="betslip__item-header">
                                    <span class="betslip__text bold">
                                        {{ getTranslatedText(`${value.title}`) }}
                                        <span class="betslip__text bets" v-if="value.bets > 1">({{ getTranslatedText('bets [quantity]', { quantity: value.bets }) }})</span>
                                    </span>
                                    <span class="betslip__text">{{ renderDate(value.eventDate) }}</span>
                                    <span class="betslip__text">{{ renderPrice(value.price) }}</span>
                                </div>
                                <div class="betslip__item-footer">
                                    <div class="betslip__list-number" v-for="(v, i) in value.selection" :key="i">
                                        <div class="betslip__item-number" v-for="(number, Numberindex) in v" :key="Numberindex">
                                            <span class="betslip__text">{{ number }}</span>
                                        </div>
                                    </div>
                                    <img v-if="!$store.state.isScreenMobile" :src="require(`@/public/theme/${themePath}/img/common/close.svg`)" @error="$event.target.src = require(`@/public/theme/${themePath}/img/common/close.svg`)" @click="onShowConfirmRemoveItem(value)" />
                                    <img v-else :src="require(`@/public/theme/${themePath}/img/common/close-white.svg`)" @error="$event.target.src = require(`@/public/theme/${themePath}/img/common/close-white.svg`)" @click="onShowConfirmRemoveItem(value)" />
                                </div>
                            </div>
                        </div>
                        <div class="betslip__action">
                            <div class="betslip__action-total">
                                <span class="betslip__text">{{ getTranslatedText('total') }}</span>
                                <span class="betslip__text">{{ renderPrice(this.$store.state.totalCart) }}</span>
                            </div>
                            <div class="betslip__action-box-button">
                                <ButtonCommon :isNoHover="true" value="clear" :isLoading="isLoadingButton" nameFunction="on-show-confirm-betslip" />
                                <ButtonCommon type="error" :isNoHover="true" value="add" :isLoading="isLoadingButton" nameFunction="on-submit-transact" />
                            </div>
                        </div>
                    </template>
                </div>
                <div v-if="isToggle" class="betslip__footer" @click="onToggleBetslip()">
                    <span class="betslip__header-title">{{ getTranslatedText('BETSLIP') }}</span>
                    <div class="betslip__header-quantity">{{ $store.state.quantity }}</div>
                    <img :src="require(`@/public/theme/${themePath}/img/common/arrow.png`)" @error="$event.target.src = require(`@/public/theme/${themePath}/img/common/arrow.svg`)" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import EventBus from '@/utils/EventBus'
import gamesService from '@/services/games.service'
import languageMixins from '@/mixins/language/language-mixins'
import pathAssetsMixins from '@/mixins/pathAssets/path-assets-mixins'
import ButtonCommon from '@/components/common/button/index.vue'

import { convertDate, formatDate, formatPrice, isArrayEmpty, isStrEmpty } from '@/utils/utils'
import { getToken } from '@/services/axios'
import { listMenugames } from '@/resources/listMenuGames'

export default {
    mixins: [languageMixins, pathAssetsMixins],
    components: { ButtonCommon },
    props: {
        isLoadingPage: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            isLoadingButton: false,
            isToggle: false,
            listMenugames,
        }
    },
    created() {
        EventBus.$on('on-remove-item-betslip', (param) => this.onRemoveItem(param))
        EventBus.$on('on-show-confirm-betslip', () => this.onShowConfirmClear())
        EventBus.$on('on-clear-betslip', () => this.onClearBetslip())
        EventBus.$on('on-submit-transact', () => this.onSubmitTransac())
        EventBus.$on('on-toggle-betslip', () => this.onToggleBetslip())
    },
    destroyed() {
        EventBus.$off('on-remove-item-betslip')
        EventBus.$off('on-show-confirm-betslip', null)
        EventBus.$off('on-clear-betslip')
        EventBus.$off('on-submit-transact')
        EventBus.$off('on-toggle-betslip')
    },
    methods: {
        // action
        onShowConfirmClear() {
            const param = {
                description: 'Are you sure you want to delete?',
                nameFunction: 'on-clear-betslip',
            }
            EventBus.$emit('on-show-dialog', param)
        },

        onShowConfirmRemoveItem(item) {
            const param = {
                description: 'Are you sure you want to delete?',
                nameFunction: 'on-remove-item-betslip',
                paramFunction: {
                    item,
                },
            }
            EventBus.$emit('on-show-dialog', param)
        },

        onRemoveItem(param) {
            const payload = {
                item: param.item,
            }
            this.$store.dispatch('onRemoveItemCart', payload)
        },

        onClearBetslip() {
            this.$store.dispatch('onClearCart')
        },

        onToggleBetslip() {
            if (this.$store.state.isScreenMobile) {
                this.isToggle = !this.isToggle
                EventBus.$emit('on-toggle-betslip-mobile', this.isToggle)
            }
        },

        onChangeTabBetslip(step) {
            const payload = {
                step,
            }
            this.$store.dispatch('onSwitchBetslip', payload)
        },

        renderQuantity() {
            let { isShow590, isShow645, isShow459, isShow545, quantity, cart } = this.$store.state

            if (!isShow590 || !isShow645 || !isShow459 || !isShow545) {
                if (isShow590) quantity = cart.filter((x) => x.gameType === 'loto-590').length
                if (isShow645) quantity = cart.filter((x) => x.gameType === 'lotto-6-45').length
                if (isShow459) quantity = cart.filter((x) => x.gameType === 'loto-4-59').length
                if (isShow545) quantity = cart.filter((x) => x.gameType === 'lotto-5-45').length
            }

            // if (isShow590 && !isShow645) quantity = cart.filter((x) => !x.isPariMutuelLotto).length
            // if (!isShow590 && isShow645) quantity = cart.filter((x) => x.isPariMutuelLotto).length

            return quantity
        },

        renderQuantityCart(step) {
            let type = 'Loto 4/59'

            if (step === 1) {
                type = 'loto-590'
            } else if (step === 2) {
                type = 'lotto-6-45'
            } else if (step === 3) {
                type = 'loto-4-59'
            } else {
                type = 'lotto-5-45'
            }

            let cartRender = this.$store.state.cart.filter((x) => x.gameType === type)
            return cartRender.length
        },

        onShowStatusTransac(code, message) {
            if (code === 200) {
                const param = {
                    text: message || `Transaction completed!`,
                    type: 'success',
                }
                EventBus.$emit('show-notications', param)
                this.$store.dispatch('onClearCart')

                this.isLoadingButton = false
            } else {
                const param = {
                    text: message || `system error!`,
                    type: 'error',
                }
                EventBus.$emit('show-notications', param)
                this.isLoadingButton = false
            }
        },

        async onSubmitTransac() {
            try {
                this.isLoadingButton = true
                const token = getToken()
                if (!isStrEmpty(token && this.$store.state.isLogin)) {
                    const { stepCart, transact, transact645, transact459, transact545 } = this.$store.state
                    let payload = []

                    if (stepCart === 1) {
                        payload = transact
                    } else if (stepCart === 2) {
                        payload = transact645
                    } else if (stepCart === 3) {
                        payload = transact459
                    } else {
                        payload = transact545
                    }

                    payload.forEach((item) => {
                        delete item.id
                    })

                    let isMultiLotto = stepCart === 1
                    let type = isMultiLotto ? 'multi-lotto' : 'pari-mutuel-lotto'

                    const resp = await gamesService.transactLotto(payload, type)

                    if (resp.code === 200) {
                        this.onShowStatusTransac(200, resp.message)
                        parent.postMessage({ command: 'refreshbalance' }, '*')
                    } else {
                        let messgae = resp.message
                        this.onShowStatusTransac(401, messgae)
                    }
                } else {
                    const param = {
                        text: `please login!`,
                        type: 'error',
                    }
                    EventBus.$emit('show-notications', param)
                    this.isLoadingButton = false
                }
            } catch (error) {
                this.isLoadingButton = false

                console.log(error)
            }
        },

        // format
        renderDate(date) {
            const dateFormat = formatDate(convertDate(date))

            let valueDate = `${dateFormat.day}/${dateFormat.month}/${dateFormat.year} ${dateFormat.hour24h}:${dateFormat.minutes}`

            return valueDate
        },

        renderPrice(price) {
            return formatPrice(price)
        },
    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/layouts/_betslip.scss';
</style>
