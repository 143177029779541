<template>
    <div class="history">
        <div class="history-menu">
            <MenuHistory />
        </div>
        <div class="history-content">
            <TransactionHistory />
        </div>
    </div>
</template>

<script>
import TransactionHistory from '@/components/history/transaction-history/index.vue'
import MenuHistory from '@/components/history/menu/index.vue'

export default {
    components: { TransactionHistory, MenuHistory },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/history/_index.scss';
</style>
