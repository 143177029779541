import { getAccount, getCurrency, getCustomerId, getexternalIdType } from './axios'
import BaseService from './base.service'

const API_URL = process.env.VUE_APP_API_URL
interface multiLottoInterface {
    account: string
    currency: string
}

interface listEventsInterface {
    account: string
}

class GamesService extends BaseService {
    getListGames(type) {
        const payload: multiLottoInterface = {
            account: getAccount() || '',
            currency: getCurrency() || '',
        }
        return this.post(`${API_URL}/api/${type}/games`, payload).then(({ data }) => data)
    }

    getListEvents = async (typeGame: string) => {
        const ACCOUNT = getAccount() || ''
        const payload: listEventsInterface = {
            account: ACCOUNT,
        }

        return this.post(`${API_URL}/api/${typeGame}/draws`, payload).then(({ data }) => data)
    }

    transactLotto = (entries: any, typeGame: string) => {
        const CURRENCY = getCurrency() || ''
        const ACCOUNT = getAccount() || ''
        const CUSTOMER_ID = getCustomerId() || ''
        const EXTERNAL_ID_TYPE = getexternalIdType() || ''

        let payload = {
            account: ACCOUNT,
            additionalInformation: '',
            currency: CURRENCY,
            retail: 'yes',
            customerID: CUSTOMER_ID,
            external_id_type: EXTERNAL_ID_TYPE,
            bets: [
                {
                    entries: entries,
                },
            ],
        }

        return this.post(`${API_URL}/api/${typeGame}/transact`, payload).then(({ data }) => data)
    }
}

export default new GamesService()
