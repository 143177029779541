<template>
    <div class="history__menu">
        <h2 class="history__menu-title">{{ getTranslatedText('lotto winnings') }}</h2>
        <div class="history__menu-list">
            <template v-for="(v, i) in menu">
                <template v-if="isCheckShowMenu(v)">
                    <div class="history__menu-list-item" :class="{ active: v.step === step }" :key="i" @click="() => redirectUrl(v.step)">
                        <div class="history__menu-list-item-icon">
                            <img :src="require(`@/public/theme/${themePath}/img/menu/${v.icon}`)" />
                        </div>
                        <span class="history__menu-list-item-text">
                            {{ getTranslatedText(v.title) }}
                        </span>
                    </div>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
import languageMixins from '@/mixins/language/language-mixins'
import themeMixins from '@/mixins/pathAssets/path-assets-mixins'
import routerMixins from '@/mixins/router/router-mixin'
import EventBus from '@/utils/EventBus'

import { listMenugames } from '@/resources/listMenuGames'
import { getParamQueryTheme, isStrEmpty } from '@/utils/utils'

export default {
    mixins: [languageMixins, themeMixins, routerMixins],
    props: {
        dataMenu: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            step: 1,
            menu: listMenugames,
        }
    },
    created() {
        this.setStep()

        this.redirectUrl(this.step)
    },
    methods: {
        setStep() {
            const { isShow590, isShow645, isShow545, isShow459 } = this.$store.state
            let stepHistory = 1
            if (isStrEmpty(getParamQueryTheme('step'))) {
                if (isShow545) stepHistory = 4
                if (isShow459) stepHistory = 3
                if (isShow645) stepHistory = 2
                if (isShow590) stepHistory = 1
            } else {
                stepHistory = parseInt(getParamQueryTheme('step'))
            }
            this.step = stepHistory
        },

        redirectUrl(step) {
            if (step !== this.step) {
                EventBus.$emit('on-load-data-history', step)
                this.step = step
                const { token, account, externalIdType, currency, game } = this.renderParamQuery()

                this.$router.push({ query: { step: step, token, account, externalIdType, currency, game } })
                this.routerPush('', `step=${step}`)
            }
        },

        isCheckShowMenu(item) {
            const { isShow645, isShow590, isShow459, isShow545 } = this.$store.state
            if (item.step === 1 && !isShow590) {
                return false
            } else if (item.step === 2 && !isShow645) {
                return false
            } else if (item.step === 3 && !isShow459) {
                return false
            } else if (item.step === 4 && !isShow545) {
                return false
            } else return true
        },
    },
}
</script>
<style lang="scss">
@import '~ThemePath/scss/history/_menu.scss';
</style>
