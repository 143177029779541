import BaseService from '@/services/base.service'
import { getAccount, getCurrency, getCustomerId } from './axios'

const API_URL = process.env.VUE_APP_API_URL
interface multiLottoWagers {
    account: string
    customerID: string
    limit: number
    date?: string
}

interface multiLottoWagerDetail {
    account: string
    customerID: string
    currency: string
    reference: string
    externalId: string
}

class HistoryService extends BaseService {
    listWagers(limit, date, type) {
        const ACCOUNT = getAccount() || ''
        const CUSTOMER_ID = getCustomerId() || ''
        const payload: multiLottoWagers = {
            account: ACCOUNT,
            customerID: CUSTOMER_ID,
            limit: limit,
            date: date,
        }
        return this.post(API_URL + `/api/${type}/wagers`, payload).then((response) => {
            return response.data
        })
    }

    wagerDetail(reference, externalId, type) {
        const ACCOUNT = getAccount() || ''
        const CUSTOMER_ID = getCustomerId() || ''
        const CURRENCY = getCurrency() || ''
        const payload: multiLottoWagerDetail = {
            account: ACCOUNT,
            customerID: CUSTOMER_ID,
            currency: CURRENCY,
            reference: reference,
            externalId: externalId,
        }
        return this.post(API_URL + `/api/${type}/wager`, payload).then((response) => {
            return response.data
        })
    }
}

export default new HistoryService()
