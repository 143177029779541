import { RouteConfig } from "vue-router";
// import { metaRequireAuth } from "@/router/meta-constants";

const authenticatedRoutes: Array<RouteConfig> = [
    // {
    //     path: "/example",
    //     name: "Example",
    //     meta: metaRequireAuth,
    //     component: example,
    // }
];

export default authenticatedRoutes;
