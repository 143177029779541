import i18n from '@/i18n/config'
import { isObjEmpty } from '@/utils/utils'

export default {
    data(): any {
        return {}
    },

    methods: {
        getTranslatedText(text: string, xargs: any = {}) {
            if (!text) {
                return
            }
            const rsText = text.toLowerCase()
            let translatedText: any = i18n.t(rsText.toString())

            if (!isObjEmpty(xargs)) {
                for (var key in xargs) {
                    translatedText = translatedText.replace('[' + key + ']', xargs[key])
                }
            }

            return translatedText
        },
    },
}
