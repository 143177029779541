<template>
    <div class="list-menu">
        <ul class="menu">
            <template v-for="(item, key) in menu">
                <li :key="key" class="item-menu" :class="{ active: activeMenu === item.path, home: item.title === '' }" @click="() => redirectUrl(item)" v-if="isCheckShowMenu(item)">
                    <template v-if="isCheckShowMenu(item)">
                        <img :src="require(`@/public/theme/${themePath}/img/menu/${item.icon}`)" />
                        {{ getTranslatedText(item.title) }}
                    </template>
                </li>
            </template>
        </ul>
        <div v-if="$store.state.isScreenMobile" class="cart" @click="onToggleBetslip">
            <img src="~ThemePath/img/menu/cart.png" />
            <span>{{ $store.state.quantity }}</span>
        </div>
    </div>
</template>

<script>
import EventBus from '@/utils/EventBus'
import languageMixins from '@/mixins/language/language-mixins'
import themeMixins from '@/mixins/pathAssets/path-assets-mixins'
import routerMixins from '@/mixins/router/router-mixin'

import { listMenuNavigation } from '@/resources/listNavigation'

export default {
    mixins: [languageMixins, themeMixins, routerMixins],
    data() {
        return {
            menu: listMenuNavigation,
            activeMenu: null,
            isMobile: false,
        }
    },
    created() {
        const { path, query } = this.$router.history.current
        const pathName = this.$router.history.current.path === '/games-loto' ? `${path}?loto=${query.loto}` : `${path}`
        this.activeMenu = pathName
        EventBus.$on('on-change-active-menu', (url) => {
            this.activeMenu = url
        })
    },
    destroyed() {
        EventBus.$off('on-change-active-menu')
    },
    methods: {
        redirectUrl(item) {
            const pathName = this.$router.history.current.path
            const fullPath = `${this.$router.history.current.path}?loto=${this.$router.history.current.query.loto}`
            if (item.path === fullPath) {
                return false
            }
            if (pathName === '/' && item.path === '/?step=1') {
                const param = {
                    step: 1,
                }

                EventBus.$emit('screen-game-on-handle-steps', param)
            } else if (item.path.includes('/games-loto')) {
                const lotoParam = item.path.split('=')[1]
                if (pathName !== '/games-loto') {
                    this.routerPush(item.path)
                } else if (fullPath !== item.path) {
                    this.routerPush(item.path, lotoParam)

                    const { token, account, externalIdType, currency, game } = this.renderParamQuery()

                    this.$router.push({ query: { loto: lotoParam, token, account, externalIdType, currency, game } })
                }
                EventBus.$emit('on-change-game-screen-games', lotoParam)
            } else {
                this.routerPush(item.path)
            }
            this.activeMenu = item.path
        },
        onToggleBetslip() {
            EventBus.$emit('on-toggle-betslip')
        },

        isCheckShowMenu(item) {
            const { isShow645, isShow590, isShow459, isShow545 } = this.$store.state
            if (item.step === 1 && !isShow590) {
                return false
            } else if (item.step === 2 && !isShow645) {
                return false
            } else if (item.step === 3 && !isShow459) {
                return false
            } else if (item.step === 4 && !isShow545) {
                return false
            } else return true
        },
    },
}
</script>
<style lang="scss">
@import '~ThemePath/scss/layouts/_menu.scss';
</style>
