import { generateCombinations, isArrayEmpty } from '@/utils/utils';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        cart: [],
        cartRender: [],
        quantity: 0,
        transact: [],
        transact645: [],
        transact545: [],
        transact459: [],
        isLoadingAddCart: false,
        totalCart: 0,
        isScreenMobile: false,
        isLogin: false,
        stepCart: 1,
        isShow590: true,
        isShow645: true,
        isShow545: true,
        isShow459: true,
        CURRENCY: 'GNF',
    },
    mutations: {
        SET_DATA_CART(state, data) {
            state.cart = data.cart;
            state.cartRender = data.cartRender;
            state.quantity = data.quantity;
            state.transact = data.transact;
            state.totalCart = data.totalCart;
            state.transact645 = data.transact645;
            state.transact545 = data.transact545;
            state.transact459 = data.transact459;
            localStorage.setItem('cart', JSON.stringify(data.cart));
            localStorage.setItem('cartRender', JSON.stringify(data.cartRender));
            localStorage.setItem('quantity', data.quantity);
            localStorage.setItem('transact', JSON.stringify(data.transact));
            localStorage.setItem('totalCart', JSON.stringify(data.totalCart));
            localStorage.setItem('transact645', JSON.stringify(data.transact645));
            localStorage.setItem('transact545', JSON.stringify(data.transact545));
            localStorage.setItem('transact459', JSON.stringify(data.transact459));
            state.isLoadingAddCart = false;
        },
        SET_DATA_CART_SWITCH(state, data) {
            state.totalCart = data.totalCart;
            state.cartRender = data.cartRender;
            state.stepCart = data.step;
            localStorage.setItem('totalCart', JSON.stringify(data.totalCart));
            localStorage.setItem('cartRender', JSON.stringify(data.cartRender));
            localStorage.setItem('stepCart', JSON.stringify(data.step));
        },
    },
    actions: {
        onSwitchBetslip({ commit, state }, payload) {
            const { step } = payload;
            const cart: any = state.cart;
            let type = 'Loto 4/59';
            if (step === 1) {
                type = 'loto-590';
            } else if (step === 2) {
                type = 'lotto-6-45';
            } else if (step === 3) {
                type = 'loto-4-59';
            } else {
                type = 'lotto-5-45';
            }

            let cartRender = cart.filter((x) => x.gameType === type);
            let totalCart = cartRender.reduce((a, b) => a + b.price, 0);

            const param = {
                totalCart,
                cartRender,
                step,
            };

            commit('SET_DATA_CART_SWITCH', param);
        },

        onAddToCart({ commit, state }, payload) {
            const cart: any = state.cart;
            let cartRender: any = [];
            const transact: any = state.transact;
            const transact645: any = state.transact645;
            const transact545: any = state.transact545;
            const transact459: any = state.transact459;
            let quantity: number = state.quantity;
            let totalCart: number = 0;
            let title = `${payload.title}`;
            let game = `${payload.game}`;
            let quantityBets = 1;

            if (!payload.isPariMutuelLotto) {
                title += ` ${payload.gameNumber}`;
                game += `${payload.gameNumber}`;
            }

            const numberQp = parseInt(payload.numberQp);

            payload.event_id.map((eventId, i) => {
                let item = {
                    id: new Date().getTime().toString(36).concat(performance.now().toString(), Math.random().toString()).replace(/\./g, ''),
                    parentId: payload.id,
                    eventId,
                    eventDate: payload.eventInfo[i].date,
                    eventName: payload.eventInfo[i].name,
                    selection: [payload.selection],
                    title,
                    price: payload.wager,
                    bets: 0,
                    isPariMutuelLotto: payload?.isPariMutuelLotto,
                    gameType: payload.isPariMutuelLotto ? payload.game : 'loto-590',
                };

                if (payload.isPariMutuelLotto && payload.selection.length > numberQp) {
                    quantityBets = generateCombinations(payload.selection.length, numberQp);
                    item['price'] = payload.wager * quantityBets;
                    item['bets'] = quantityBets;
                }

                cart.push(item);
            });

            let item = {
                id: payload.id,
                selection: [payload.selection],
                draws: payload.draws,
                event_id: payload.event_id,
                wager: payload.wager,
                game,
            };

            let typeGame = '';

            if (payload.game === 'lotto-5-45') {
                transact545.push(item);
                typeGame = 'lotto-5-45';
            } else if (payload.game === 'lotto-6-45') {
                transact645.push(item);
                typeGame = 'lotto-6-45';
            } else if (payload.game === 'loto-4-59') {
                transact459.push(item);
                typeGame = 'loto-4-59';
            } else {
                transact.push(item);
                typeGame = 'loto-590';
            }

            cartRender = cart.filter((x) => x.gameType === typeGame);
            totalCart = cartRender.reduce((a, b) => a + b.price, 0);
            quantity = cart.length;

            const param = {
                quantity,
                transact,
                cart,
                totalCart,
                transact645,
                transact545,
                transact459,
                cartRender,
            };

            commit('SET_DATA_CART', param);
        },
        onRemoveItemCart({ commit, state }, payload) {
            const cart: any = state.cart;
            let cartRender: any = [];
            let transact: any = state.transact;
            let transact645: any = state.transact645;
            let transact459: any = state.transact459;
            let transact545: any = state.transact545;
            let quantity: number = state.quantity;
            let totalCart: number = state.totalCart;
            const element = payload.item;

            let transactClone: any = [];

            if (element.gameType === 'lotto-6-45') {
                transactClone = transact645;
            } else if (element.gameType === 'loto-4-59') {
                transactClone = transact459;
            } else if (element.gameType === 'lotto-5-45') {
                transactClone = transact545;
            } else {
                transactClone = transact;
            }

            const index = transactClone.findIndex((x) => x.id === element.parentId);

            if (index !== -1) {
                transactClone[index].event_id = transactClone[index].event_id.filter((x) => x !== element.eventId);
            }

            if (isArrayEmpty(transactClone[index]?.event_id)) {
                transactClone.splice(index, 1);
            }

            const indexCart = cart.findIndex((x) => x.id === element.id);

            cart.splice(indexCart, 1);

            cartRender = cart.filter((x) => x.gameType === element.gameType);

            totalCart = cartRender.reduce((a, b) => a + b.price, 0);
            quantity = cart.length;
            let param = {
                quantity,
                transact,
                cart,
                totalCart,
                transact459,
                transact545,
                transact645,
                cartRender,
            };

            if (element.gameType === 'lotto-6-45') {
                param['transact645'] = transactClone;
            } else if (element.gameType === 'loto-4-59') {
                param['transact459'] = transactClone;
            } else if (element.gameType === 'lotto-5-45') {
                param['transact545'] = transactClone;
            } else {
                param['transact'] = transactClone;
            }

            commit('SET_DATA_CART', param);
        },

        onClearCart({ state, commit }) {
            let cart: any = state.cart;

            let type = 'Loto 4/59';

            if (state.stepCart === 1) {
                type = 'loto-590';
            } else if (state.stepCart === 2) {
                type = 'lotto-6-45';
            } else if (state.stepCart === 3) {
                type = 'loto-4-59';
            } else {
                type = 'lotto-5-45';
            }

            cart = cart.filter((x) => x.gameType !== type);
            let totalCart = cart.reduce((a, b) => a + b.price, 0);
            let quantity = cart.length;

            let param = {
                quantity,
                transact: state.stepCart === 1 ? [] : state.transact,
                cart: cart,
                totalCart,
                transact645: state.stepCart === 2 ? [] : state.transact645,
                transact459: state.stepCart === 3 ? [] : state.transact459,
                transact545: state.stepCart === 4 ? [] : state.transact545,
                cartRender: [],
            };
            commit('SET_DATA_CART', param);
        },
    },
});
