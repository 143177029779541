<template>
    <div class="payout-detail__content table-lotoExtra">
        <div class="table-title">{{ getTranslatedText('Loto Extra 6') }}</div>
        <div class="table-list-notes">
            <span class="table-list-notes-text"> {{ getTranslatedText('the player can choose between 2 and 5 numbers when placing a bet.') }}</span>
            <span class="table-list-notes-text"> {{ getTranslatedText('the result is based on the first 6 balls drawn during the 5/90 draw, meaning the first 5 numbers and the first bonus number.') }}</span>
            <span class="table-list-notes-text"> {{ getTranslatedText('The player can win with as little as two correct numbers') }}</span>
        </div>
        <div class="table-title">{{ getTranslatedText('Distribution of Winnings') }}</div>
        <table>
            <tr>
                <th class="game">{{ getTranslatedText('game') }}</th>
                <th class="pick-number">{{ getTranslatedText('Picked Numbers') }}</th>
                <th class="stake">{{ getTranslatedText('stake') }}</th>
                <th class="winning 2number">{{ getTranslatedText('Winnings Match [number] Numbers', {number: 2}) }}</th>
                <th class="winning 3number">{{ getTranslatedText('Winnings Match [number] Numbers', {number: 3}) }}</th>
                <th class="winning 4number">{{ getTranslatedText('Winnings Match [number] Numbers', {number: 4}) }}</th>
                <th class="winning 5number">{{ getTranslatedText('Winnings Match [number] Numbers', {number: 5}) }}</th>
            </tr>
            <tr v-for="(value, index) in listLotoPayouts.extra6" :key="index">
                <td class="cell">
                    <span>{{ getTranslatedText(value.game) }}</span>
                </td>
                <td class="cell">
                    <span>{{ value.pick }}</span>
                </td>
                <td class="cell">
                    <span>{{ value.stake }}</span>
                </td>
                <td class="cell">
                    <span>{{ value.winning2 }}</span>
                </td>
                <td class="cell">
                    <span>{{ value.winning3 }}</span>
                </td>
                <td class="cell">
                    <span>{{ value.winning4 }}</span>
                </td>
                <td class="cell">
                    <span>{{ value.winning5 }}</span>
                </td>
            </tr>
        </table>
        <div class="table-note">
            <i> {{ getTranslatedText('Note: No Win For 1 Correct Number') }} </i>
        </div>
   
        <div class="table-title">{{ getTranslatedText('Loto Extra 7') }}</div>
        <div class="table-list-notes">
            <span class="table-list-notes-text"> {{ getTranslatedText('the player can choose between 2 and 5 numbers when placing a bet.') }}</span>
            <span class="table-list-notes-text"> {{ getTranslatedText('the result is based on the 7 balls drawn during the 5/90 draw, meaning the first 5 numbers and the two bonus numbers.') }}</span>
            <span class="table-list-notes-text"> {{ getTranslatedText('Le joueur peut gagner à partir de deux bons numéros.') }}</span>
        </div>
        <div class="table-title">{{ getTranslatedText('Distribution of Winnings') }}</div>
        <table>
            <tr>
                <th class="game">{{ getTranslatedText('game') }}</th>
                <th class="pick-number">{{ getTranslatedText('Picked Numbers') }}</th>
                <th class="stake">{{ getTranslatedText('stake') }}</th>
                <th class="winning 2number">{{ getTranslatedText('Winnings Match [number] Numbers', {number: 2}) }}</th>
                <th class="winning 3number">{{ getTranslatedText('Winnings Match [number] Numbers', {number: 3}) }}</th>
                <th class="winning 4number">{{ getTranslatedText('Winnings Match [number] Numbers', {number: 4}) }}</th>
                <th class="winning 5number">{{ getTranslatedText('Winnings Match [number] Numbers', {number: 5}) }}</th>
            </tr>
            <tr v-for="(value, index) in listLotoPayouts.extra7" :key="index">
                <td class="cell">
                    <span>{{ getTranslatedText(value.game) }}</span>
                </td>
                <td class="cell">
                    <span>{{ value.pick }}</span>
                </td>
                <td class="cell">
                    <span>{{ value.stake }}</span>
                </td>
                <td class="cell">
                    <span>{{ value.winning2 }}</span>
                </td>
                <td class="cell">
                    <span>{{ value.winning3 }}</span>
                </td>
                <td class="cell">
                    <span>{{ value.winning4 }}</span>
                </td>
                <td class="cell">
                    <span>{{ value.winning5 }}</span>
                </td>
            </tr>
        </table>
        <div class="table-note">
            <i> {{ getTranslatedText('Note: No Win For 1 Correct Number') }} </i>
        </div>
    </div>
</template>

<script>
import languageMixins from '@/mixins/language/language-mixins'
import listLotoPayouts from '@/resources/data/payouts.json'
import { formatPrice } from '@/utils/utils'

export default {
    mixins: [languageMixins],
    data() {
        return {
            listLotoPayouts,
        }
    },

    methods: {
        renderPrice(price) {
            let decimal = this.isPariMutuelLotto ? 2 : 0
            return formatPrice(price, decimal)
        },
    },
}
</script>
<style lang="scss">
@import '~ThemePath/scss/payouts/_payout-detail.scss';
</style>
