<template>
    <div class="transaction-history">
        <div class="transaction-history__header">{{ getTranslatedText('Transaction History') }}</div>
        <LoadingScreen v-if="isLoadingScreen" />
        <template v-else>
            <div class="transaction-history__date">
                <DatePicker />
            </div>
            <LoadingScreen v-if="isLoadingFetchData" />
            <template v-else>
                <b v-if="listWagers.length > 1">
                    {{ getTranslatedText(`Your Last [length] Tickets`, { length: listWagers.length }) }}
                </b>
                <b v-else> {{ getTranslatedText(`Your Last [length] Ticket`, { length: listWagers.length }) }}</b>
                <div class="transaction-history__content" :class="listWagers.length === 0 ? 'no-content' : ''" @scroll="loadMore">
                    <v-expansion-panels>
                        <v-expansion-panel v-for="(item, key) in listWagers" :key="key" @click="onExpansionPanelClick(item, openedPanel)">
                            <v-expansion-panel-header>
                                <div class="transaction-history__content__header">
                                    <b class="date">{{ renderDate(item.date) }}</b>
                                    <span class="bet"
                                        >{{ getTranslatedText('Bet') }}:<b>{{ item.currencySymbol + ' ' + renderPrice(item.totalWager) }}</b></span
                                    >
                                    <b class="name">{{ `L-${item.transactionId}` }}</b>
                                    <span class="earning"
                                        >{{ getTranslatedText('Earnings') }}: <b>{{ item.currencySymbol + ' ' + renderPrice(item.totalWinnings) }}</b></span
                                    >
                                    <b class="name empty_content" v-if="item.raffleEntryCount"></b>
                                    <span class="earning reuse_earning_css_class" v-if="item.raffleEntryCount">{{ getTranslatedText('Raffle Entry') }}: <b>{{ renderPrice(item.raffleEntryCount) }}</b></span
                                    >
                                </div>
                            </v-expansion-panel-header>
                            <TabelMulti v-if="isHistory590" :dataSource="wagerDetail" :isMobile="isMobile" />
                            <TabelPariMutuel v-else :dataSource="wagerDetail" :isMobile="isMobile" />
                            <div class="transaction-history__loading-expansion">
                                <Loading v-if="isLoadingExpansion && item.transactionId === transactionId" :isLoadingPage="false"></Loading>
                            </div>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <div class="loading"><Loading v-if="loading" :isLoadingPage="false" /></div>
                </div>
                <div class="transaction-history__button">
                    <ButtonCommon type="default" :isNoHover="true" value="Refresh" :isLoading="isLoadingRefresh" nameFunction="onClickRefresh" />
                </div>
            </template>
        </template>
    </div>
</template>

<script>
import languageMixins from '@/mixins/language/language-mixins'
import HistoryService from '@/services/history.service'
import ButtonCommon from '@/components/common/button/index.vue'
import Loading from '@/components/common/loading'
import DatePicker from '@/components/common/datepicker/index.vue'
import LoadingScreen from '@/components/common/loading/index.vue'
import TabelMulti from '@/components/history/transaction-history/table-multi-lotto.vue'
import TabelPariMutuel from '@/components/history/transaction-history/table-loto.vue'
import EventBus from '@/utils/EventBus'
import { formatPrice, isStrEmpty, formatDate, getParamQueryTheme, convertDate } from '@/utils/utils'

const LIMIT = process.env.VUE_APP_LIMIT

export default {
    mixins: [languageMixins],
    components: { ButtonCommon, Loading, DatePicker, LoadingScreen, TabelMulti, TabelPariMutuel },
    data() {
        return {
            isLoadingScreen: true,
            isLoadingFetchData: false,
            isLoadingRefresh: false,
            isLoadingExpansion: false,
            transactionId: -1,
            openedPanel: undefined,
            listWagers: [],
            wagerDetail: null,
            date: null,
            stopLoad: false,
            page: 1,
            itemsPerPage: LIMIT,
            loading: false,
            isMobile: false,
            isHistory590: true,
            stepHistory: 1
        }
    },
    watch: {
        isHistory590() {
            this.openedPanel = undefined
            this.listWagers = []
            this.wagerDetail = null
            this.date = null
            this.page = 1
        },
    },
    created() {
        let stepHistory = 1
        const { isShow590, isShow645, isShow545, isShow459 } = this.$store.state

        if (isStrEmpty(getParamQueryTheme('step'))) {
            if (isShow545) stepHistory = 4
            if (isShow459) stepHistory = 3
            if (isShow645) stepHistory = 2
            if (isShow590) stepHistory = 1
        } else {
            stepHistory = parseInt(getParamQueryTheme('step'))
        }

        this.isHistory590 = isShow590
        this.stepHistory = stepHistory
        this.onLoadDataHistory(parseInt(stepHistory))
        EventBus.$on('on-load-data-history', (step) => {
            this.onLoadDataHistory(step)
        })

        EventBus.$on('onClickRefresh', () => {
            this.handleRefresh()
        })
        EventBus.$on('date', (value) => {
            this.page = 1
            this.date = value
            let type = this.isGetTypeHistory()

            this.fetchDataWagers(this.itemsPerPage, value, type, stepHistory)
        })
    },
    destroyed() {
        EventBus.$off('screen-game-on-handle-steps', () => null)
        EventBus.$off('on-load-data-history')
        EventBus.$off('onClickRefresh', () => {})
        EventBus.$off('date')
    },
    beforeDestroy() {
        if (typeof window !== 'undefined') {
            window.removeEventListener('resize', this.onResize, { passive: true })
        }
    },

    mounted() {
        this.onResize()
        window.addEventListener('resize', this.onResize, { passive: true })
    },
    methods: {
        async fetchDataWagers(limit, date, type, step) {
            try {
                const resp = await HistoryService.listWagers(limit, date, type)
                if (resp.code === 200) {
                    let listWagers = resp.data
                    if (step !== 1) {
                        let type = ''
                        if (step === 2) type = 'lotto-6-45'
                        if (step === 3) type = 'loto-4-59'
                        if (step === 4) type = 'lotto-5-45'
                        listWagers = listWagers.filter((x) => x.betslip.some((betslip) => betslip.game_slug === type))
                    }

                    this.listWagers = listWagers
                    this.isLoadingRefresh = false
                    this.loading = false
                    this.isLoadingScreen = false
                    this.isLoadingFetchData = false
                } else {
                    this.listWagers = []
                    this.isLoadingRefresh = false
                    this.loading = false
                    this.isLoadingScreen = false
                    this.isLoadingFetchData = false
                }
                if (this.listWagers.length !== this.page * this.itemsPerPage) {
                    this.stopLoad = true
                    this.isLoadingScreen = false
                    this.isLoadingFetchData = false
                }
            } catch (error) {
                console.log(error)
                this.isLoadingRefresh = false
                this.isLoadingFetchData = false
                this.isLoadingScreen = false
                this.loading = false
            }
        },

        async fetchDataWagerDetail(reference, externalId, type) {
            try {
                const resp = await HistoryService.wagerDetail(reference, externalId, type)
                if (resp.code === 200) {
                    this.wagerDetail = resp.data
                    this.isLoadingExpansion = false
                } else {
                    this.isLoadingExpansion = false
                }
            } catch (error) {
                console.log(error)
            }
        },

        onLoadDataHistory(step) {
            this.isLoadingScreen = true
            if (step === 1) {
                this.fetchDataWagers(this.itemsPerPage, '', 'multi-lotto', step)
                this.isHistory590 = true
            } else {
                this.fetchDataWagers(this.itemsPerPage, '', 'pari-mutuel-lotto', step)
                this.isHistory590 = false
            }
        },

        onExpansionPanelClick(value) {
            if (this.transactionId != value.transactionId) {
                this.isLoadingExpansion = true
                this.transactionId = value.transactionId
                this.wagerDetail = null
                let type = this.isGetTypeHistory()
                this.fetchDataWagerDetail(value.reference, value.externalId, type)
            } else {
                this.isLoadingExpansion = false
                this.transactionId = -1
                this.wagerDetail = null
            }
        },

        handleRefresh() {
            this.isLoadingRefresh = true
            this.stopLoad = false
            this.openedPanel = null
            this.date = null
            EventBus.$emit('clearDate', this.date)
            let type = this.isGetTypeHistory()

            this.fetchDataWagers(this.itemsPerPage, this.date, type)
        },

        renderDate(date) {
            const dateFormat = formatDate(convertDate(date))
            let valueDate = `${dateFormat.year}-${dateFormat.month}-${dateFormat.day} ${dateFormat.hour24h}:${dateFormat.minutes}`
            return valueDate
        },

        loadMore(e) {
            let { scrollTop, clientHeight, scrollHeight } = e.target
            if (!this.loading && scrollTop + clientHeight >= (scrollHeight * 4) / 5 && !this.stopLoad) {
                this.loading = true
                let type = this.isGetTypeHistory()
                setTimeout(() => {
                    this.page++
                    this.fetchDataWagers(this.page * this.itemsPerPage, this.date, type)
                }, 1000)
            }
        },

        renderPrice(price) {
            return formatPrice(price)
        },

        onResize() {
            this.isMobile = window.innerWidth <= 480
        },

        isGetTypeHistory() {
            let type = this.isHistory590 ? 'multi-lotto' : 'pari-mutuel-lotto'
            return type
        },
    },
}
</script>
<style lang="scss">
@import '~ThemePath/scss/history/_transaction-history.scss';
</style>
