<template>
    <div class="promotions">
        <LoadingScreen v-if="isLoading" />
        <div v-else class="promotions--games">
            <template v-if="!promotionSelected">
                <div class="promotions--games__item" v-for="(p, index) in promotionData" :key="index"
                    @click="showInfo(p)">
                    <p class="games__item--name">{{ p.game_name }} {{ getTranslatedText(p.game_name_extra) }}</p>
                </div>
                <p v-show="promotionData.length == 0" class="text__empty">{{ getTranslatedText('no data') }}</p>
            </template>

            <template v-else>
                <div class="promotions--events">
                    <h2 class="promotions--title">
                        {{ promotionSelected.game_name }}
                        {{ getTranslatedText(promotionSelected.game_name_extra) }}
                    </h2>
                    <template v-if="promotionSelected.promotion_type === 'prize-promotion'">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        {{ getTranslatedText("date/time of event") }}
                                    </th>
                                    <th scope="col">{{ getTranslatedText("market") }}</th>
                                    <th scope="col">{{ getTranslatedText("old prize") }}</th>
                                    <th scope="col">{{ getTranslatedText("new prize") }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr :key="index" v-for="(event, index) in promotionSelected.events">
                                    <td>{{ renderDate(event.draw_date) }}</td>
                                    <td>{{ (event.market_name) }}</td>
                                    <td>{{ renderPrice(event.old_prize) }}
                                        {{ $store.state.CURRENCY }}
                                    </td>
                                    <td>{{ renderPrice(event.new_prize) }}
                                        {{ $store.state.CURRENCY }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                    <template v-else-if="promotionSelected.promotion_type === 'raffle-promotion'">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        {{ getTranslatedText("date/time of event") }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr :key="index" v-for="(event, index) in promotionSelected.events">
                                    <td>{{ renderDate(event.draw_date) }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        {{ getTranslatedText("type of raffle promotion") }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {{ promotionSelected.entry_type.includes('%s') ?
                                            getTranslatedText(`${promotionSelected.entry_type.replace('%s','[entry_amount]').replace('%s','[currency]')}`,
                                            {
                                                entry_amount: promotionSelected.entry_amount, 
                                                currency: promotionSelected.currency || this.$store.state.CURRENCY
                                            }) :
                                            getTranslatedText(promotionSelected.entry_type) 
                                        }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">{{ getTranslatedText("tiers") }}</th>
                                    <th scope="col">{{ getTranslatedText("prize") }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr :key="index" v-for="(prize, index) in promotionSelected.prizes">
                                    <td>{{ prize.tier }}</td>
                                    <td v-if="promotionSelected.prize_type === 'monetary'">{{ renderPrice(prize.prize) }} {{ promotionSelected.currency || $store.state.CURRENCY }}</td>
                                    <td v-else>{{ prize.prize }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                </div>
                <ButtonCommon value="back" class-name="button button__back" :isNoHover="true"
                    @on-click-button-common="back" />
            </template>
        </div>
    </div>
</template>
<script>
import languageMixins from '@/mixins/language/language-mixins'
import PromotionService from '@/services/promotion.service';

import LoadingScreen from '@/components/common/loading/index.vue';
import ButtonCommon from "@/components/common/button/index.vue";

import { convertDate, formatDate, formatPrice } from '@/utils/utils';

export default {
    components: { LoadingScreen, ButtonCommon },
    mixins: [languageMixins],
    data() {
        return {
            isLoading: false,
            promotionData: [],
            promotionSelected: null,
        }
    },
    mounted() {
        this.getActivePromotions()
    },
    methods: {
        async getActivePromotions() {
            try {
                this.isLoading = true;
                const resp = await PromotionService.getActivePromotions();
                if (resp.code === 200 && resp.status === "success") {
                    this.promotionData = resp.data
                }
            } catch (error) {
                console.log(error)
            } finally {
                this.isLoading = false;
            }
        },

        showInfo(promotion) {
            this.promotionSelected = promotion;
        },

        back() {
            this.promotionSelected = null;
        },

        renderDate(date) {
            const dateFormat = formatDate(convertDate(date))
            let valueDate = `${dateFormat.year}-${dateFormat.month}-${dateFormat.day} ${dateFormat.hour24h}:${dateFormat.minutes}`
            return valueDate
        },

        renderPrice(price) {
            let decimal = this.isPariMutuelLotto ? 2 : 0
            return formatPrice(price, decimal)
        },
    }
}
</script>

<style lang="scss">
@import '~ThemePath/scss/promotions/index.scss';
</style>