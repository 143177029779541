<template>
    <v-expansion-panel-content v-if="dataSource">
        <div class="table loto-mutuel">
            <div class="item">{{ getTranslatedText('No') }}</div>
            <div class="item">{{ getTranslatedText('Selection') }}</div>
            <div class="item">{{ getTranslatedText('type') }}</div>
            <div v-if="!isMobile" class="item">{{ getTranslatedText('Wager') }}</div>
            <div v-if="!isMobile" class="item">{{ getTranslatedText('draw date') }}</div>
            <div v-if="!isMobile" class="item">{{ getTranslatedText('Earnings') }}</div>
        </div>
        <div class="empty" v-if="dataSource.bets.entries.length === 0">{{ getTranslatedText('No Result') }}</div>
        <template v-else>
            <details v-for="(data, index) in dataSource.bets.entries" :key="index">
                <summary class="table-content loto-mutuel">
                    <div class="table-icon-expanded" v-if="data.wins && data.wins.length > 0 && !isMobile"></div>
                    <div class="item">{{ index + 1 }}</div>
                    <div class="item numbers-cell">
                        <div class="numbers-cell__content">
                            <span class="numbers" v-for="(value, i) in data.entry.bet" :key="i">
                                <b v-if="i > 0">+</b>
                                <span v-for="(v, k) in value" :key="k">{{ v }}</span>
                            </span>
                        </div>
                    </div>
                    <div class="item">
                        {{ data.entry.type }}
                        <div class="item-text" v-if="isShowCombination(data)">({{ getTranslatedText('bets [quantity]', { quantity: renderCombinations(data) }) }})</div>
                    </div>
                    <div v-if="!isMobile" class="item">{{ dataSource.transaction.currency + ' ' + renderPrice(data.entry.wager) }}</div>
                    <div v-if="!isMobile" class="item">{{ renderDate(data.event.drawDate) }}</div>
                    <div v-if="!isMobile" class="item">{{ dataSource.transaction.currency + ' ' + renderPrice(data.entry.winnings) }}</div>
                    <div class="block loto-mutuel" v-if="isMobile">
                        <div class="item-block numbers-cell no-scroll">
                            <b>{{ getTranslatedText('draw date') }}</b>
                            <div class="numbers-cell__content">
                                {{ renderDate(data.event.drawDate) }}
                            </div>
                        </div>
                        <div class="item-block numbers-cell no-scroll">
                            <b>{{ getTranslatedText('Earnings') }}</b>
                            <div class="numbers-cell__content">
                                {{ dataSource.transaction.currency + ' ' + renderPrice(data.entry.winnings) }}
                            </div>
                        </div>
                        <div class="item-block numbers-cell no-scroll">
                            <b>{{ getTranslatedText('Wager') }}</b>
                            <div class="numbers-cell__content">
                                {{ dataSource.transaction.currency + ' ' + renderPrice(data.entry.wager) }}
                            </div>
                        </div>
                    </div>
                </summary>
                <div class="box-loto-mutuel-winnings" v-if="data.wins && data.wins.length > 0">
                    <div class="table loto-mutuel-winnings">
                        <div class="item">{{ getTranslatedText('No') }}</div>
                        <div class="item">{{ getTranslatedText('matches') }}</div>
                        <div class="item">{{ getTranslatedText('Wager') }}</div>
                    </div>
                    <div class="table-content loto-mutuel-winnings" v-for="(win, winIndex) in data.wins" :key="winIndex">
                        <div class="item">{{ win.betId }}</div>
                        <div class="item numbers-cell">
                            <div class="numbers-cell__content">
                                <span class="numbers" v-for="(b, j) in win.bet" :key="j">
                                    <b v-if="i > 0">+</b>
                                    <span v-for="(numberW, l) in b" :key="l">{{ numberW }}</span>
                                </span>
                            </div>
                        </div>
                        <div class="item">{{ dataSource.transaction.currency + ' ' + renderPrice(win.winnings) }}</div>
                    </div>
                </div>
            </details>
        </template>
        <table class="raffle-table" v-if="dataSource.bets.raffles && dataSource.bets.raffles.length > 0">
            <tr v-for="(data, index) in dataSource.bets.raffles" :key="index">
                <td>{{ getTranslatedText('Raffle Id') }}: {{ data.promotion_id }}</td>
                <td>{{ getTranslatedText('Raffle Entry') }}: {{ data.entry_count }}</td>
                <td>{{ getTranslatedText('Raffle Status') }}: {{ getTranslatedText(data.status) }}</td>
                <td style="text-align: right;" v-if="data.winnings && data.winnings.length > 0">
                    {{ getTranslatedText('Raffle Winnings') }}<br>
                    <span>{{ getTranslatedText('total winnings') }}: {{ (data.currency ?? dataSource.transaction.currency) + ' ' + renderPrice(data.total_winnings) }}</span>
                    <span v-for="(d, i) in data.winnings" :key="i"><br>{{ getTranslatedText('Tier') }} {{ d.tier }}: 
                        {{ 
                            data.prize_type_slug === 'monetary' ? (data.currency ?? dataSource.transaction.currency) + ' ' + renderPrice(d.winnings) : d.winnings
                        }}
                    </span>
                </td>
                <td style="text-align: right;" v-else>{{ getTranslatedText('Raffle Winnings') }}: {{ (data.currency ?? dataSource.transaction.currency) }} 0</td>
            </tr>
        </table>
    </v-expansion-panel-content>
</template>

<script>
import languageMixins from '@/mixins/language/language-mixins'

import { convertDate, formatDate, formatPrice, generateCombinations } from '@/utils/utils'

export default {
    mixins: [languageMixins],
    props: {
        dataSource: {
            type: Object,
            default: () => {},
        },
        isMobile: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {}
    },
    methods: {
        renderPrice(price) {
            return formatPrice(price)
        },
        isShowCombination(data) {
            const bets = data.entry.bet
            const numberQp = parseInt(data.market.market.slice(-1))
            let quantity = 0
            for (let index = 0; index < bets.length; index++) {
                quantity += bets[index].length
            }

            return quantity > numberQp ? true : false
        },
        renderCombinations(data) {
            const bets = data.entry.bet
            const numberQp = parseInt(data.market.market.slice(-1))
            let quantity = 0
            for (let index = 0; index < bets.length; index++) {
                quantity += bets[index].length
            }
            return generateCombinations(quantity, numberQp)
        },
        renderDate(date) {
            const dateFormat = formatDate(convertDate(date))
            let valueDate = `${dateFormat.year}-${dateFormat.month}-${dateFormat.day} ${dateFormat.hour24h}:${dateFormat.minutes}`
            return valueDate
        },
    },
}
</script>
