// import axios from 'axios';
import axiosAuth, { getToken } from "@/services/axios";
import { isObjEmpty, isStrEmpty } from "@/utils/utils";

class BaseService {
    get<T = unknown>(url: string, data?: unknown) {
        return axiosAuth.get(url, {
            data,
        });
    }

    post<T = unknown>(url: string, data: unknown) {
        let param: any = data
        let token: any = getToken()
        if(!isObjEmpty(param) && !isStrEmpty(token)) {
            param['client_token'] = token
        }
        return axiosAuth.post(url, param);
    }

    put<T = unknown>(url: string, data: unknown) {
        return axiosAuth.put(url, data);
    }

    delete<T = unknown>(url: string) {
        return axiosAuth.delete(url);
    }
}

export default BaseService;
