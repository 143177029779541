<template>
    <div class="datepicker">
        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :nudge-right="40" lazy transition="scale-transition" offset-y full-width max-width="290px" min-width="290px">
            <template v-slot:activator="{ on }">
                <v-text-field v-model="computedDateFormatted" :label="getTranslatedText('Select a Date')" persistent-hint prepend-icon="event" v-on="on" outlined dense> </v-text-field>
                <img src="~ThemePath/img/results/calendar.png/" @click="onOpenCalendar"/>
            </template>
            <v-date-picker v-model="date" no-title @input="menu = false" @change="onChangeDate()"></v-date-picker>
        </v-menu>
    </div>
</template>

<script>
import languageMixins from '@/mixins/language/language-mixins'
import themeMixins from '@/mixins/pathAssets/path-assets-mixins'
import EventBus from '@/utils/EventBus'

export default {
    mixins: [languageMixins, themeMixins],
    data: () => ({
        date: null,
        menu: false,
    }),
    created() {
        EventBus.$on('clearDate', (value) => {
            this.date = value
        })
    },
    destroyed() {
        EventBus.$off('clearDate', () => {})
    },
    computed: {
        computedDateFormatted() {
            return this.formatDate(this.date)
        },
    },
    methods: {
        formatDate(date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },

        onOpenCalendar() {
            this.menu = !this.menu
        },

        onChangeDate() {
            EventBus.$emit('date', this.date)
        },
    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/common/_date.scss';
</style>