<template>
    <div class="loto">
        <LoadingScreen v-if="loadingPage" />
        <template v-else>
            <div class="loto-mennu">
                <Menu :dataMenu="this.dataShowGame" :nameGame="dataGames.title" :isMultiLotto="isMultiLotto" />
            </div>
            <div class="loto-content">
                <LoadingScreen v-if="loadingPageComponent" />
                <template v-else>
                    <div class="loto-page">
                        <NavComponent v-if="(step === 2 || step === 3) && !isPariMutuelLotto" :propsDataGames="dataGames" :step="step" />
                        <TableResults v-if="step === 1" :isMultiLotto="isMultiLotto" :propsDataGames="dataGames" />
                        <EventsComponent v-else-if="step === 2" :propsDataGames="dataGames" :gameSlugActive="gameSlugActive" :isHiddenButtonBack="true" />
                        <NumberComponent v-else-if="step === 3" :propsDataSource="dataNumber" />
                        <Promotion v-else-if="step === 5"/>
                        <PayoutDetail v-else :step="stepPayout" :propsDataGames="dataGames" :isPariMutuelLotto="isPariMutuelLotto" />
                    </div>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
import Menu from '@/components/layouts/menu-loto/index.vue'
import PayoutDetail from '@/components/payouts/payout-detail/index.vue'
import getListGamesMixin from '@/mixins/getListGames/get-list-games-mixin'
import LoadingScreen from '@/components/common/loading/index.vue'
import TableResults from '@/components/results/table-results/index.vue'
import NumberComponent from '@/components/games/number/index.vue'
import EventsComponent from '@/components/games/events/index.vue'
import NavComponent from '@/components/games/nav/index.vue'
import Promotion from "@/components/promotion/index.vue"
import EventBus from '@/utils/EventBus'

export default {
    mixins: [getListGamesMixin],
    components: { Menu, PayoutDetail, LoadingScreen, TableResults, NumberComponent, NavComponent, EventsComponent, Promotion },
    data() {
        return {
            listLoto: [],
            stepPayout: 1,
            gameSelect: '',
            dataNumber: [],
            loadingPageComponent: false,
            isPariMutuelLotto: false,
        }
    },
    created() {
        EventBus.$on('on-change-step-screen-loto', (param) => this.onSwitchScreen(param))
        EventBus.$on('screen-game-on-handle-steps', (param) => this.onHanleSteps(param))
    },
    destroyed() {
        EventBus.$off('on-change-step-screen-loto')
        EventBus.$off('screen-game-on-handle-steps', () => this.onHanleSteps())
    },
    methods: {
        onSwitchScreen(param) {
            if (param.step === 4 && !param.isPariMutuelLotto) {
                let stepPayout 

                if(param?.title === 'turbo') {
                    stepPayout = 1
                } else if (param?.title === 'nap') {
                    stepPayout = 2
                } else if (param?.title === 'chance') {
                    stepPayout = 3
                } else {
                    stepPayout = 4
                }

                this.stepPayout = stepPayout
            } else {
                this.stepPayout = 5
            }
            this.isPariMutuelLotto = param.isPariMutuelLotto
            this.step = param.step
        },
        onHanleSteps(param) {
            this.loadingPageComponent = true
            if (param.step === 2) this.dataGames = param.games
            if (param.step === 3) this.dataNumber = param.number
            this.isPariMutuelLotto = this.dataGames.isPariMutuelLotto
            this.step = param.step
            this.loadingPageComponent = false
        },
    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/loto/_loto.scss';
</style>
