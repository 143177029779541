<template>
    <div class="table-results">
        <div class="table-results__header">{{ renderHeaderTitle() }}</div>
        <div class="table-results__content">
            <div v-if="isMultiLotto || $store.state.stepCart === 4 || $store.state.stepCart === 3" class="table-results__content__date">
                <DatePicker />
                <b>{{ formatDate(this.date) }}</b>
            </div>
            <div v-else class="table-results__content__date">
                <v-select v-model="selectedDatePariLotto" :items="dataSelectPariLotto" :label="getTranslatedText('select date')"></v-select>
            </div>
            <LoadingScreen v-if="loadingResults" />
            <template v-else>
                <details class="table-results__content__list" v-for="(item, key) in listResults" :key="key" open>
                    <summary class="table-results__content__summary" :class="{ pointer: item.tiers && item.tiers.length > 0 }">
                        <div class="table-results__content__header">
                            <p class="name">{{ getTranslatedText(item.draw_name) }}</p>
                            <p class="date">{{ renderDate(item.draw_date) }}</p>
                        </div>
                        <div class="numbers-cell">
                            <div class="number" v-for="(data, index) in item.results" :key="index">
                                <p v-for="(value, i) in data" :key="i">
                                    {{ value }}
                                </p>
                                <b v-if="index !== item.results.length - 1">+</b>
                            </div>
                        </div>
                    </summary>
                    <table class="table-results__table-tiers" v-if="item.tiers && item.tiers.length > 0">
                        <thead>
                            <tr>
                                <th class="table-results__table-tiers__name">{{ getTranslatedText('Match Numbers') }}</th>
                                <th class="table-results__table-tiers__winners">{{ getTranslatedText('Winners') }}</th>
                                <th class="table-results__table-tiers__payouts">{{ getTranslatedText('Payout') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(tier, i) in item.tiers" :key="i">
                                <td>{{ renderNameNumberN(tier.name) }}</td>
                                <td>{{ renderPrice(tier.winners) }}</td>
                                <td>{{ renderPrice(tier.prize) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </details>
                <div class="empty" v-if="listResults.length === 0">{{ getTranslatedText('No Result') }}</div>
            </template>
        </div>
        <div class="table-results__button" v-if="isMultiLotto">
            <ButtonCommon type="default" :isNoHover="true" value="Previous Date" :isLoading="isLoadingPrevious" nameFunction="onClickPreviousDate" />
            <ButtonCommon type="error" :isNoHover="true" value="Next Date" :isLoading="isLoadingNext" :isDisable="isDisableNext" nameFunction="onClickNextDate" />
        </div>
    </div>
</template>

<script>
import languageMixins from '@/mixins/language/language-mixins'
import themeMixins from '@/mixins/pathAssets/path-assets-mixins'
import DatePicker from '@/components/common/datepicker/index.vue'
import ResultsService from '@/services/results.service'
import EventBus from '@/utils/EventBus'
import ButtonCommon from '@/components/common/button/index.vue'
import LoadingScreen from '@/components/common/loading/index.vue'

import { convertDate, formatDate, formatPrice, getAllSaturdays } from '@/utils/utils'

export default {
    mixins: [languageMixins, themeMixins],
    components: { DatePicker, ButtonCommon, LoadingScreen },
    props: {
        isMultiLotto: {
            type: Boolean,
            default: false,
        },
        propsDataGames: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            date: new Date().toISOString().substr(0, 10),
            listResults: [],
            loadingResults: false,
            isLoadingPrevious: false,
            isLoadingNext: false,
            isDisableNext: false,
            selectedDatePariLotto: '',
            dataSelectPariLotto: [],
        }
    },
    watch: {
        isMultiLotto() {
            this.date = new Date().toISOString().substr(0, 10)
            this.listResults = []
            this.loadingResults = false
            this.isLoadingPrevious = false
            this.isLoadingNext = false
            this.isDisableNext = false
            this.selectedDatePariLotto = ''
            this.dataSelectPariLotto = []
            this.isMultiLotto ? this.fetchDataResults(this.date) : this.onGetlistSaturday()
        },
        selectedDatePariLotto() {
            this.loadingResults = true
            this.fetchDataResultsPariLoto(this.selectedDatePariLotto)
        },
    },
    created() {
        const step = this.$store.state.stepCart

        this.isMultiLotto ? this.fetchDataResults(this.date) : step !== 1 ? this.fetchDataResultsPariLoto('') : this.onGetlistSaturday()

        EventBus.$on('date', (value) => {
            this.date = value
            const step = this.$store.state.stepCart
            step !== 1 ? this.fetchDataResultsPariLoto(value) : this.fetchDataResults(this.date)
        })
        EventBus.$on('onClickPreviousDate', () => {
            this.handlePreviousDate()
        })
        EventBus.$on('onClickNextDate', () => {
            this.handleNextDate()
        })
    },
    destroyed() {
        EventBus.$off('date', () => {})
        EventBus.$off('onClickPreviousDate', () => {})
        EventBus.$off('onClickNextDate', () => {})
    },
    methods: {
        async fetchDataResultsPariLoto(date) {
            try {
                const resp = await ResultsService.listResults(date, 'pari-mutuel-lotto')
                if (resp.code === 200) {    
                    const step = this.$store.state.stepCart
                    let listResults = resp.data
                    if (step !== 1) {
                        let type = ''
                        if (step === 2) type = 'lotto 6 of 45'
                        if (step === 3) type = 'loto 4/59'
                        if (step === 4) type = 'lotto 5 of 45'
                        listResults = listResults.filter((x) => x.game.toLowerCase() === type.toLowerCase())
                    }
                    this.listResults =listResults
                }
                this.loadingResults = false
            } catch (error) {
                console.log(error)
            }
        },

        async fetchDataResults(date) {
            if (date === new Date().toISOString().substr(0, 10)) {
                this.isDisableNext = true
            } else {
                this.isDisableNext = false
            }
            try {
                const resp = await ResultsService.listResults(date, 'multi-lotto')
                if (resp.code === 200) {
                    this.listResults = resp.data
                }
                this.isLoadingPrevious = false
                this.isLoadingNext = false
            } catch (error) {
                console.log(error)
            }
        },

        onGetlistSaturday() {
            let dataSelectPariLotto = getAllSaturdays()
            let dateDefault = dataSelectPariLotto[0]
            this.selectedDatePariLotto = dateDefault
            this.dataSelectPariLotto = dataSelectPariLotto
        },

        handlePreviousDate() {
            this.isLoadingPrevious = true
            const day = new Date(this.date)
            day.setDate(day.getDate() - 1)
            this.date = day.toISOString().substr(0, 10)
            this.fetchDataResults(this.date)
        },

        handleNextDate() {
            this.isLoadingNext = true
            const day = new Date(this.date)
            day.setDate(day.getDate() + 1)
            this.date = day.toISOString().substr(0, 10)
            this.fetchDataResults(this.date)
        },

        formatDate(date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },

        renderDate(date) {
            const dateFormat = formatDate(convertDate(date))
            let valueDate = `${dateFormat.hour24h}H${dateFormat.minutes}`
            return valueDate
        },

        renderPrice(price) {
            return formatPrice(price, 2)
        },

        renderNameNumberN(name) {
            return name.slice(-1)
        },

        renderHeaderTitle() {
            let title = 'Results - Change to Loto '
            if (this.propsDataGames.name === 'lotto-6-45') {
                title += '6/45'
            } else if (this.propsDataGames.name === 'loto-4-59') {
                title += '4/59'
            } else if (this.propsDataGames.name === 'lotto-5-45') {
                title += '5/45'
            } else {
                title += '5/90'
            }

            return this.getTranslatedText(title)
        },
    },
}
</script>
<style lang="scss">
@import '~ThemePath/scss/results/_table-results.scss';
</style>
