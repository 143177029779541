import BaseService from '@/services/base.service'
import { getAccount } from './axios'

const API_URL = process.env.VUE_APP_API_URL

class ResultsService extends BaseService {
    listResults(date, type: string) {
        const ACCOUNT = getAccount() || ''

        return this.post(API_URL + `/api/${type}/results`, { account: ACCOUNT, date }).then((response) => {
            return response.data
        })
    }
}

export default new ResultsService()
