import BaseService from '@/services/base.service'
import { getAccount, getCurrency } from './axios'

const API_URL = process.env.VUE_APP_API_URL
interface multiLottoInterface {
    account: string
    currency: string
}

class PromotionService extends BaseService {
    getActivePromotions() {
        const payload: multiLottoInterface = {
            account: getAccount() || '',
            currency: getCurrency() || '',
        }

        return this.post(API_URL + `/api/active-promotions`, payload).then((response) => {
            return response.data
        })
    }

    getRaffleResults() {
        const payload = {
            account: getAccount() || ''
        }

        return this.post(API_URL + `/api/promotion-result`, payload).then((response) => {
            return response.data
        })
    }
}

export default new PromotionService()
