import { isStrEmpty, getParamQueryTheme } from '@/utils/utils'

export default {
    data() {
        return {
            token: '',
            account: '',
            externalIdType: '',
            currency: '',
            game: '',
        }
    },

    methods: {
        routerPush(path, search) {
            const { origin } = document.location
            let url = `${origin}${path}`
            if (!isStrEmpty(search)) {
                url += `?${search}`
            } else {
                const { token, account, externalIdType, currency, game } = this.renderParamQuery()

                url = `${path}`
                this.$router.push({ path: url, query: { token, account, externalIdType, currency, game } })
            }
            parent.postMessage({ command: 'changeUrl', url }, '*')
        },

        renderParamQuery() {
            let token = ''
            let account = ''
            let externalIdType = ''
            let currency = ''
            let game = ''

            if (!isStrEmpty(getParamQueryTheme('token'))) {
                token = getParamQueryTheme('token')
            }

            if (!isStrEmpty(getParamQueryTheme('account'))) {
                account = getParamQueryTheme('account')
            }

            if (!isStrEmpty(getParamQueryTheme('externalIdType'))) {
                externalIdType = getParamQueryTheme('externalIdType')
            }

            if (!isStrEmpty(getParamQueryTheme('currency'))) {
                currency = getParamQueryTheme('currency')
            }

            if (!isStrEmpty(getParamQueryTheme('game'))) {
                game = getParamQueryTheme('game')
            }

            let param = {
                token,
                account,
                externalIdType,
                currency,
                game,
            }
            return param
        },
    },
}
