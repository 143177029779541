import { RouteConfig } from 'vue-router'
import GamesScreen from '@/views/games/index.vue'
import HistoryComponent from '@/views/history/index.vue'
import RaffleResultsComponent from '@/views/raffle-results/index.vue'
import LotoCompoennt from '@/views/loto/index.vue'

const publicRoutes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'gamesLists',
        meta: {
            hiddenBetslip: true,
        },
        component: GamesScreen,
    },
    {
        path: '/games-loto',
        name: 'Games-Loto',
        component: LotoCompoennt,
    },
    {
        path: '/history',
        name: 'History',
        component: HistoryComponent,
    },
    {
        path: '/raffle-results',
        name: 'Results (Raffle)',
        component: RaffleResultsComponent,
    },
]

export default publicRoutes
