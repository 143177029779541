<template>
    <div class="nav-games">
        <div class="nav-games__tab-list-games">
            <div class="nav-games__tab-item-games" :class="{ active: v.active }" v-for="(v, i) in dataSource" :key="i" @click="() => onChangeGames(v.number, i)">
                <span>{{ getTranslatedText(`loto [title] [number]`, {
                        title,
                        number: v.number
                    }) 
                }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import pathAssetsMixins from '@/mixins/pathAssets/path-assets-mixins'
import languageMixins from '@/mixins/language/language-mixins'
import ButtonCommon from '@/components/common/button/index.vue'
import SelectFilter from '@/components/games/events/select/index.vue'
import EventBus from '@/utils/EventBus'
import { isArrayEmpty, isObjEmpty } from '@/utils/utils'

export default {
    mixins: [pathAssetsMixins, languageMixins],
    components: { ButtonCommon, SelectFilter },
    props: {
        propsDataGames: {
            type: Object,
            default: () => {},
        },
        step: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            dataSource: [],
            title: '',
        }
    },
    watch: {
        propsDataGames() {
            this.fetchdata()
        },
    },
    created() {
        this.fetchdata()
    },
    methods: {
        fetchdata() {
            this.dataSource = this.propsDataGames.listRanDomNumber
            let isActive = this.dataSource.filter((x) => x.active)
            if (isArrayEmpty(isActive)) {
                this.dataSource[0].active = true
            }
            this.title = this.propsDataGames.title
        },
        onChangeGames(number, index) {
            const dataSource = this.dataSource.map((v, i) => {
                const item = {
                    ...v,
                    active: i === index,
                }
                return item
            })

            this.dataSource = dataSource
            const param = {
                gameNumber: number,
                numberQp: this.title === 'turbo' ? 2 : number,
            }

            if(!isObjEmpty(dataSource[index]?.allowed_picks)) {
                param['allowed_picks'] = dataSource[index]?.allowed_picks
                param['price'] = dataSource[index]?.price
            }

            EventBus.$emit(`on-change-nav-games-${this.step}`, param)
        },
    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/games/_nav.scss';
</style>
