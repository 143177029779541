<template>
    <div class="games">
        <template v-if="$store.state.isShow590">
            <img v-if="!$store.state.isScreenMobile" :src="require(`@/public/theme/${themePath}/img/banner/banner.png`)" @error="$event.target.src = require(`@/public/theme/${themePath}/img/banner/banner.png`)" />
            <img v-else :src="require(`@/public/theme/${themePath}/img/banner/banner-mobile.png`)" @error="$event.target.src = require(`@/public/theme/${themePath}/img/banner/banner-mobile.png`)" />
        </template>
        <LoadingScreen v-if="loadingPage" />
        <template v-else>
            <EmptyComponent v-if="dataSource.length === 0" text="no data" />
            <div class="games__list" v-else>
                <div class="games__item" v-for="(v, i) in dataSource" :key="i">
                    <div 
                        v-if="v.banner === 'lotto-6-45' && $store.state.isShow645 || v.banner === 'lotto-5-45'  && $store.state.isShow545 || v.banner === 'loto-4-59' && $store.state.isShow459" 
                        class="games__games-item-banner" 
                        :key="index" >
                        <img v-if="!$store.state.isScreenMobile" :src="require(`@/public/theme/${themePath}/img/banner/${v.banner}.png`)" @error="$event.target.src = require(`@/public/theme/${themePath}/img/banner/banner.png`)" />
                        <img v-else :src="require(`@/public/theme/${themePath}/img/banner/${v.banner}-mobile.png`)" @error="$event.target.src = require(`@/public/theme/${themePath}/img/banner/banner-mobile.png`)" />
                    </div>
                    <div class="games__item-header">
                        <span>{{ getTranslatedText(`${v.isPariMutuelLotto ? '' : 'loto '}${v.title}`) }}</span>
                    </div>
                    <div class="games__item-list-number">
                        <div class="games__item-item-number" v-for="(value, index) in v.listRanDomNumber" :key="index" @click="onClickGames(v, value, index)">
                            <span v-if="v.isPariMutuelLotto">{{ getTranslatedText('play') }}</span>
                            <span v-else>{{
                                getTranslatedText(`${v.title} [number]`, {
                                    number: value.number,
                                })
                            }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import pathAssetsMixins from '@/mixins/pathAssets/path-assets-mixins'
import languageMixins from '@/mixins/language/language-mixins'
import getListGames from '@/mixins/getListGames/get-list-games-mixin'
import LoadingScreen from '@/components/common/loading/index.vue'
import EmptyComponent from '@/components/common/empty/index.vue'
import routerMixins from '@/mixins/router/router-mixin'
import EventBus from '@/utils/EventBus'
import { isObjEmpty } from '@/utils/utils'

export default {
    mixins: [pathAssetsMixins, languageMixins, getListGames, routerMixins],
    components: { LoadingScreen, EmptyComponent },
    data() {
        return {
            dataSource: [],
            loadingPage: true,
            dataBanner: [
                {
                    name: 'isShow645',
                    img: 'banner-645',
                },
                {
                    name: 'isShow459',
                    img: 'banner-459',
                },
                {
                    name: 'isShow545',
                    img: 'banner-645'
                }
            ]
        }
    },
    methods: {
        onClickGames(games, itemGame, index) {
            games.listRanDomNumber[index].active = true
            let param = {
                title: games.title,
                gameNumber: itemGame.number,
                name: `${games.name}`,
                price: itemGame?.price || games.price,
                numberQp: games.title === 'turbo' ? 2 : itemGame.number,
                listRanDomNumber: games.listRanDomNumber,
                isPariMutuelLotto: games.isPariMutuelLotto,
                jackpot: games.jackpot,
            }

            if(!isObjEmpty(itemGame.allowed_picks)) {
                param['allowed_picks'] = itemGame.allowed_picks
            }

            if (games.isPariMutuelLotto) {
                param['maxPicks'] = games.listRanDomNumber[0].maxPicks
                param['numberQp'] = games.listRanDomNumber[0].number
            }
            
            localStorage.setItem('configGame', JSON.stringify(param))

            if (games.name === 'lotto-6-45') {
                this.routerPush('/games-loto?loto=loto-6-45')
                EventBus.$emit('on-change-active-menu', '/games-loto?loto=loto-6-45')
                this.$store.state.stepCart = 2
            } else if (games.name === 'loto-4-59') {
                this.routerPush('/games-loto?loto=loto-4-59')
                EventBus.$emit('on-change-active-menu', '/games-loto?loto=loto-4-59')
                this.$store.state.stepCart = 3
            } else if (games.name === 'lotto-5-45') {
                this.routerPush('/games-loto?loto=loto-5-45')
                EventBus.$emit('on-change-active-menu', '/games-loto?loto=loto-5-45')
                this.$store.state.stepCart = 4
            } else {
                this.routerPush('/games-loto?loto=loto-5-90')
                this.$store.state.stepCart = 1
                EventBus.$emit('on-change-active-menu', '/games-loto?loto=loto-5-90')
            }
        },
    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/games/_list.scss';
</style>
