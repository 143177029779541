<template>
    <div class="payout-detail">
       <TableResults v-if="step === 0" />
        <template v-else>
            <div class="payout-detail__header">{{ getTranslatedText(`Loto ${name} Winnings`) }}</div>
            <Payout645 v-if="propsDataGames.name === 'lotto-6-45'" />
            <Payout459 v-if="propsDataGames.name === 'loto-4-59'" />
            <Payout545 v-if="propsDataGames.name === 'lotto-5-45'" />
            <PayoutExtra v-if="step === 4" />
            <template v-else>
                <div class="payout-detail__content" v-for="(item, key) in listLoto" :key="key">
                    <template v-if="isPariMutuelLotto">
                        <table>
                            <tr>
                                <th class="num">{{ getTranslatedText('Numbers N') }}</th>
                                <th class="odd">
                                    <span>{{ getTranslatedText('Odds') }}</span>
                                    <p>({{ getTranslatedText('1 over') }})</p>
                                </th>
                                <th class="Payout">{{ getTranslatedText('Payout') }}</th>
                            </tr>
                            <tr v-for="(value, index) in item.loto" :key="index">
                                <td class="cell">
                                    <span>{{ value.numbersN }}</span>
                                </td>
                                <td>{{ renderPrice(value.odds) }}</td>
                                <td class="cell">
                                    <span :key="i">{{ value.payout }}</span>
                                </td>
                            </tr>
                        </table>
                    </template>
                    <template v-else>
                        <b>{{ getTranslatedText(item.name + ': ' + item.countWin) }}</b>
                        <table>
                            <tr>
                                <th class="number">{{ getTranslatedText('Number Of Winning Numbers') }}</th>
                                <th class="bet">{{ getTranslatedText('Bet') }}</th>
                                <th class="earnings">{{ getTranslatedText('Earnings') }}</th>
                            </tr>
                            <tr>
                                <td class="cell">
                                    <span v-for="(data, index) in item.winningNumbers" :key="index">{{ getTranslatedText(data.value) }}</span>
                                </td>
                                <td>{{ renderPrice(item.bet) + ' ' + item.currency }}</td>
                                <td class="cell">
                                    <span v-for="(value, i) in item.earnings" :key="i">{{ renderPrice(value) }}</span>
                                </td>
                            </tr>
                        </table>
                    </template>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
import languageMixins from '@/mixins/language/language-mixins'
import listLotoPayouts from '@/resources/data/payouts.json'
import EventBus from '@/utils/EventBus'
import TableResults from '@/components/results/table-results/index.vue'

import Payout645 from '@/components/payouts/payout-detail/payout645/index.vue'
import Payout545 from '@/components/payouts/payout-detail/payout545/index.vue'
import Payout459 from '@/components/payouts/payout-detail/payout459/index.vue'
import PayoutExtra from '@/components/payouts/payout-detail/payoutExtra/index.vue'
import { formatPrice } from '@/utils/utils'

export default {
    mixins: [languageMixins],
    components: { TableResults, Payout645, Payout545, Payout459, PayoutExtra },
    props: {
        step: {
            type: Number,
            default: 1,
        },
        isPariMutuelLotto: {
            type: Boolean,
            default: false,
        },
        propsDataGames: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            listLoto: [],
            name: 'Turbo',
            listLotoPayouts,
        }
    },
    watch: {
        step() {
            this.fetchDataLoto(this.step)
        },
    },
    created() {
        this.fetchDataLoto(this.step)
    },
    destroyed() {
        EventBus.$off('on-change-step-screen-results')
    },

    methods: {
        fetchDataLoto(step) {
            if (step === 1) {
                this.listLoto = listLotoPayouts.turbo
                this.name = 'Turbo'
            } else if (step === 2) {
                this.listLoto = listLotoPayouts.nap
                this.name = 'nap'
            } else if (step === 3) {
                this.listLoto = listLotoPayouts.chance
                this.name = 'chance'
            } else if(step === 4) {
                this.name = 'Extra'
            } else {
                if (this.propsDataGames.name === 'lotto-5-45') {
                    this.name = '5/45'
                } else if (this.propsDataGames.name === 'loto-4-59') {
                    this.name = '4/59'
                } else {
                    this.name = '6/45'
                }
            }
            this.step = step
        },

        renderPrice(price) {
            let decimal = this.isPariMutuelLotto ? 2 : 0
            return formatPrice(price, decimal)
        },
    },
}
</script>
<style lang="scss">
@import '~ThemePath/scss/payouts/_payout-detail.scss';
</style>
