<template>
    <v-main class="main-app">
        <div class="main-content">
            <ListComponets />
        </div>
    </v-main>
</template>

<script>
import ListComponets from '@/components/games/list/index.vue'
export default {
    components: { ListComponets },
    data() {
        return {}
    },
}
</script>
