<template>
    <div class="transaction-history">
        <div class="transaction-history__header">{{ getTranslatedText('Results (Raffle)') }}</div>
        <LoadingScreen v-if="isLoadingScreen" />
        <template v-else>
            <div class="promotion-select" v-if="promotions && promotions.length > 0">
                <label><b>{{ getTranslatedText('select promotion') }}:</b></label>
                <select class="app-select__list" v-model="selectedPromotionId" @change="promotionChanged">
                    <option class="app-select__item" v-for="(option, index) in promotions" :key="index"
                        :value="option.promotion_id">{{ getSelectName(option.promotion_id) }}</option>
                </select>
            </div>
            <div class="empty" v-if="!promotions || promotions.length == 0">{{ getTranslatedText('No Result') }}</div>
            <LoadingScreen v-if="isLoadingFetchData" />
            <template v-else>
                <div class="list_item" v-for="( x, i ) in  prizes " :key="i">
                    <div class="list_item_child">
                        <div class="list_item_left">
                            <span>
                                <span>{{ getTranslatedText('prize') }}:</span>
                                <b>{{ x.tier }}</b>
                            </span>
                        </div>
                        <div class="list_item_right">
                            <span>
                                <span class="hl">{{ getTranslatedText('prize amount') }}:</span>
                                <b>{{ selectedPromotion.prize_slug === 'monetary' ? renderPrice(x.winnings) : x.winnings }} {{ selectedPromotion.prize_slug === 'monetary' ? (x.currency || "GNF") : "" }}</b>
                            </span>
                        </div>
                    </div>
                    <div class="list_item_child">
                        <span>
                            <span class="hl">{{ getTranslatedText('winning code') }}:</span>
                            <b>{{ x.transaction_id }}</b>
                        </span>
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
import languageMixins from '@/mixins/language/language-mixins'
import Loading from '@/components/common/loading'
import LoadingScreen from '@/components/common/loading/index.vue'
import PromotionService from '@/services/promotion.service';
import { formatPrice } from '@/utils/utils'

const LIMIT = process.env.VUE_APP_LIMIT

export default {
    mixins: [languageMixins],

    components: { Loading, LoadingScreen },

    data() {
        return {
            isLoadingScreen: true,
            isLoadingFetchData: false,
            isLoadingRefresh: false,
            isLoadingExpansion: false,
            openedPanel: undefined,
            stopLoad: false,
            page: 1,
            itemsPerPage: LIMIT,
            loading: false,
            isMobile: false,
            promotions: null,
            selectedPromotionId: 0,
            selectedPromotion: {},
            prizes: []
        }
    },

    created() {
        this.fetchRaffleResults()
    },

    destroyed() {
    },

    beforeDestroy() {
        if (typeof window !== 'undefined') {
            window.removeEventListener('resize', this.onResize, { passive: true })
        }
    },

    mounted() {
        this.onResize()
        window.addEventListener('resize', this.onResize, { passive: true })
    },

    methods: {
        getSelectName(id) {
            return this.getTranslatedText('Promotion ID') + " " + id;
        },

        async fetchRaffleResults() {
            try {
                this.isLoading = true;
                const resp = await PromotionService.getRaffleResults();
                if (resp.code === 200) {
                    this.promotions = resp.data
                    if (this.promotions && this.promotions.length > 0) {
                        this.selectedPromotionId = this.promotions[0].promotion_id
                        this.promotionChanged()
                    }
                    this.isLoadingRefresh = false
                    this.loading = false
                    this.isLoadingScreen = false
                    this.isLoadingFetchData = false
                } else {
                    this.isLoadingRefresh = false
                    this.loading = false
                    this.isLoadingScreen = false
                    this.isLoadingFetchData = false
                }
            } catch (error) {
                console.log(error)
                this.isLoadingRefresh = false
                this.isLoadingFetchData = false
                this.isLoadingScreen = false
                this.loading = false
            }
        },

        onResize() {
            this.isMobile = window.innerWidth <= 480
        },

        promotionChanged(p) {
            this.selectedPromotion = this.promotions.find(obj => {
                return obj.promotion_id === this.selectedPromotionId
            })
            if (this.selectedPromotion) {
                this.prizes = this.selectedPromotion.prizes
            }
        },

        renderPrice(price) {
            return formatPrice(price)
        },

    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/history/_transaction-history.scss';
@import '~ThemePath/scss/promotions/promotion_select.scss';
</style>
