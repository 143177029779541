<template>
    <v-app v-if="!isLoading">
        <EmptyPage v-if="emptyGame" />
        <v-main class="background-main" v-else>
            <Menu />
            <div class="main-body" :class="{ 'has-betslip': !this.$route.meta.hiddenBetslip || $store.state.isScreenMobile }">
                <div class="main-screen">
                    <router-view />
                </div>
                <BetslipCompoent v-if="!this.$route.meta.hiddenBetslip || $store.state.isScreenMobile" />
            </div>
        </v-main>
        <Notications />
        <DialogComponent />
    </v-app>
</template>
<script>
import jwt_decode from 'jwt-decode'
import Menu from '@/components/layouts/menu/index.vue'
import Notications from '@/components/common/notications/index.vue'
import BetslipCompoent from '@/components/layouts/betslip/index.vue'
import DialogComponent from '@/components/common/dialog/index.vue'
import EmptyPage from '@/views/404/Error404.vue'
import routerMixins from '@/mixins/router/router-mixin'

import { getParamQueryTheme, isStrEmpty } from '@/utils/utils'
import { deleteAccount, deleteCurrency, deleteExternalIdType, deleteGame, deleteToken, getGame, setAccount, setCurrency, setCustomerId, setexternalIdType, setGame, setToken } from './services/axios'

export default {
    components: { Menu, Notications, BetslipCompoent, DialogComponent, EmptyPage },
    mixins: [routerMixins],
    data() {
        return {
            isChecklogin: false,
            screenWidth: window.innerWidth,
            isLoading: true,
            emptyGame: false,
        }
    },
    watch: {
        screenWidth(value) {
            if (value <= 768) {
                this.$store.state.isScreenMobile = true
            } else {
                this.$store.state.isScreenMobile = false
            }
        },
    },
    created() {
        this.fetchData()
        if (this.screenWidth <= 768) {
            this.$store.state.isScreenMobile = true
            this.$store.state.isScreenPhone = this.screenWidth <= 480 ? true : false
        } else {
            this.$store.state.isScreenMobile = false
        }
        window.addEventListener('resize', this.handleResize)
        window.addEventListener('message', this.getMessage)
    },
    mounted() {
        if (!isStrEmpty(getParamQueryTheme('token'))) {
            setToken(getParamQueryTheme('token'))
            this.checkLogin(getParamQueryTheme('token'))
        } else {
            deleteToken()
        }

        if (!isStrEmpty(getParamQueryTheme('account'))) {
            setAccount(getParamQueryTheme('account'))
        } else {
            deleteAccount()
        }

        if (!isStrEmpty(getParamQueryTheme('currency'))) {
            setCurrency(getParamQueryTheme('currency'))
            this.$store.state.CURRENCY = getParamQueryTheme('currency')
        } else {
            deleteCurrency()
        }

        if (!isStrEmpty(getParamQueryTheme('externalIdType'))) {
            setexternalIdType(getParamQueryTheme('externalIdType'))
        } else {
            deleteExternalIdType()
        }

        if (!isStrEmpty(getParamQueryTheme('game'))) {
            let game = getParamQueryTheme('game')
            if (game !== 'loto590' && game !== 'loto645' && game !== 'loto545' && game !== 'loto459') {
                this.emptyGame = true
                this.isLoading = false

                return false
            }

            this.$store.state.isShow590 = game === 'loto590'
            this.$store.state.isShow645 = game === 'loto645'
            this.$store.state.isShow459 = game === 'loto459'
            this.$store.state.isShow545 = game === 'loto545'

            let step = 1

            if (game === 'loto590') {
                step = 1
            } else if (game === 'loto645') {
                step = 2
            } else if (game === 'loto459') {
                step = 3
            } else {
                step = 4
            }

            const payload = {
                step,
            }
            this.redirectUrl(game)
            this.$store.dispatch('onSwitchBetslip', payload)
            this.emptyGame = false
        }

        this.isLoading = false
    },
    methods: {
        getMessage(event) {
            if (event.data.command === 'set_data') {
                if (!isStrEmpty(event.data.data.token)) {
                    setToken(event.data.data.token)
                    this.checkLogin(event.data.data.token)
                }

                if (!isStrEmpty(event.data.data.account)) {
                    setAccount(event.data.data.account)
                }

                if (!isStrEmpty(event.data.data.currency)) {
                    setCurrency(event.data.data.currency)
                }

                if (!isStrEmpty(event.data.data.externalIdType)) {
                    setexternalIdType(event.data.data.externalIdType)
                }

                if (!isStrEmpty(event.data.data.game)) {
                    let game = event.data.data.game
                    if (game !== 'loto590' && game !== 'loto645') {
                        this.emptyGame = true
                        this.isLoading = false
                        return false
                    }

                    this.$store.state.isShow590 = game === 'loto590'
                    this.$store.state.isShow645 = game === 'loto645'
                    this.$store.state.isShow459 = game === 'loto459'
                    this.$store.state.isShow545 = game === 'loto545'

                    let step = 1

                    if (game === 'loto590') {
                        step = 1
                    } else if (game === 'loto645') {
                        step = 2
                    } else if (game === 'loto459') {
                        step = 3
                    } else {
                        step = 4
                    }

                    const payload = {
                        step,
                    }
                    this.$store.dispatch('onSwitchBetslip', payload)
                    this.redirectUrl(game)
                    this.emptyGame = false
                }
            }
        },
        fetchData() {
            const cart = !isStrEmpty(localStorage.getItem('cart')) ? JSON.parse(localStorage.getItem('cart')) : []
            const cartRender = !isStrEmpty(localStorage.getItem('cartRender')) ? JSON.parse(localStorage.getItem('cartRender')) : []
            const quantity = !isStrEmpty(localStorage.getItem('quantity')) ? localStorage.getItem('quantity') : 0
            const totalCart = !isStrEmpty(localStorage.getItem('totalCart')) ? localStorage.getItem('totalCart') : 0
            const transact = !isStrEmpty(localStorage.getItem('transact')) ? JSON.parse(localStorage.getItem('transact')) : []
            const transact645 = !isStrEmpty(localStorage.getItem('transact645')) ? JSON.parse(localStorage.getItem('transact645')) : []
            const transact459 = !isStrEmpty(localStorage.getItem('transact459')) ? JSON.parse(localStorage.getItem('transact459')) : []
            const transact545 = !isStrEmpty(localStorage.getItem('transact545')) ? JSON.parse(localStorage.getItem('transact545')) : []
            const stepCart = !isStrEmpty(localStorage.getItem('stepCart')) ? JSON.parse(localStorage.getItem('stepCart')) : 1
            this.$store.state.cart = cart
            this.$store.state.cartRender = cartRender
            this.$store.state.transact = transact
            this.$store.state.transact645 = transact645
            this.$store.state.transact459 = transact459
            this.$store.state.transact545 = transact545
            this.$store.state.quantity = parseInt(quantity)
            this.$store.state.totalCart = parseInt(totalCart)
            this.$store.state.stepCart = parseInt(stepCart)
        },
        handleResize() {
            this.screenWidth = window.innerWidth
        },

        async checkLogin(token) {
            try {
                const decodedToken = jwt_decode(token)
                if (!isStrEmpty(decodedToken.customerID)) {
                    setCustomerId(decodedToken.customerID)
                }
                this.$store.state.isLogin = !isStrEmpty(decodedToken.account) && !isStrEmpty(decodedToken.customerID)
            } catch (error) {
                this.$store.state.isLogin = false
            }
        },

        redirectUrl(newGame) {
            const oldRenderGame = getGame()
            if (oldRenderGame !== newGame) {
                setGame(newGame)
                this.routerPush('/')
            } else {
                setGame(newGame)
            }
        },
    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/main.scss';
</style>
