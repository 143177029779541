/* eslint-disable max-len */
import { RouteConfig } from "vue-router";

import { metaRequireGuest } from "@/router/meta-constants";


const authenticateRoutes: Array<RouteConfig> = [
    // {
    //     path: "/example",
    //     name: "example",
    //     meta: metaRequireGuest,
    //     component: example,
    // }
];

export default authenticateRoutes;
