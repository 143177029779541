<template>
    <div class="menu-loto">
        <div class="menu-loto__content">
            <div class="menu-loto__content-title" :class="{ active: menuActive === 'payout' }">{{
                getTranslatedText('Loto payouts') }}</div>
            <ul class="menu-loto__content__menu">
                <li :class="{ active: menuItemActive === 'result' }" @click="gotoPromotion(false)">
                    <img :src="require(`@/public/theme/${themePath}/img/menu/${iconDefault}`)" />
                    <span>{{ getTranslatedText('resulted') }}</span>
                </li>
                <li :class="{ active: menuItemActive === 'promotion' }" @click="gotoPromotion(true)">
                    <img :src="require(`@/public/theme/${themePath}/img/menu/${iconDefault}`)" />
                    <span>{{ getTranslatedText('active promotion') }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import languageMixins from '@/mixins/language/language-mixins'
import themeMixins from '@/mixins/pathAssets/path-assets-mixins'
import EventBus from '@/utils/EventBus'

export default {
    mixins: [languageMixins, themeMixins],
    props: {
        dataMenu: {
            type: Array,
            default: () => [],
        },
        nameGame: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            menuActive: 'payout',
            menuItemActive: 'result',
            iconDefault: 'loto-turbo.png',
        }
    },
    methods: {
        gotoPromotion(v) {
            EventBus.$emit('show-active-promotions', v)
            this.menuActive = 'payout'
            this.menuItemActive = v ? 'promotion' : 'result'
        }
    },
}
</script>
<style lang="scss">
@import '~ThemePath/scss/layouts/_menu-loto.scss';
</style>
