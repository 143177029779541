<template>
    <div class="box-title-custom">
        <span>{{ getTranslatedText(value) }}</span>
        <slot :name="nameSlot" />
    </div>
</template>

<script>
import languageMixins from '@/mixins/language/language-mixins'

export default {
    mixins: [languageMixins],
    props: {
        value: {
            type: String,
            default: 'default',
        },
        nameSlot: {
            type: String,
            default: '',
        },
    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/common/_title.scss';
</style>
