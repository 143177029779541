import GamesService from '@/services/games.service.ts'
import EventBus from '@/utils/EventBus'
import { isArrayEmpty, isStrEmpty, getParamQueryTheme } from '@/utils/utils.ts'
import routerMixins from '@/mixins/router/router-mixin'
import languageMixins from '@/mixins/language/language-mixins'

export default {
    mixins: [routerMixins, languageMixins],
    data() {
        return {
            dataSource: [],
            dataShowGame: [],
            dataGames: [],
            isPariMutuelLotto: false,
            loadingPage: true,
            gameSelect: '',
            isMultiLotto: false,
            step: 1,
            gameSlugActive: '',
        }
    },
    created() {
        this.fetchdata()
        EventBus.$on('on-change-game-screen-games', (value) => this.onChangeGame(value))
    },
    destroyed() {
        EventBus.$off('on-change-game-screen-games', this.onChangeGame)
    },
    methods: {
        async fetchdata() {
            try {
                const { isShow590, isShow645, isShow545, isShow459 } = this.$store.state
                if (!isShow590 && !isShow645 && !isShow545 && !isShow459) {
                    this.loadingPage = false
                    return false
                }
                let resp = []

                if (isShow590) {
                    let respMulti = await GamesService.getListGames('multi-lotto')
                    if (respMulti.code === 200 && !isArrayEmpty(respMulti.data)) {
                        let dataItem = []

                        let dataSourceOrder = ['turbo', 'nap', 'chance', 'extra']
                        let listGames = respMulti.data.reduce(function (r, a) {
                            let name = a.game.config.grouping || a.game.name
                            r[name] = r[name] || []
                            r[name].push(a)
                            return r
                        }, Object.create(null))

                        let maxiumBallsSorted = 5
                        let minimumBallsSorted = 0
                        Object.keys(listGames).map((x) => {
                            let itemGame = listGames[x]
                            let allowed_picks = Boolean(itemGame[0].game.config.allowed_picks)
                            let name = itemGame[0].game.slug.substr(0, itemGame[0].game.slug.length - 1)
                            let listRanDomNumber = []
                            if(allowed_picks) {
                                itemGame.map(item => {
                                    let number = item.game.name.toLowerCase().replace(/\s/g, '').replace(x.toLowerCase(), '')
                                    let allowed_picks = item.game.config.allowed_picks
                                    let price = item.game.price
                                    const itemRandom = {
                                        number,
                                        active: false,
                                        allowed_picks,
                                        price
                                    }
                                    listRanDomNumber.push(itemRandom)

                                    listRanDomNumber = listRanDomNumber.sort((a, b) => {
                                        return a.number - b.number
                                    })
                                })
                               
                            } else {
                                minimumBallsSorted = maxiumBallsSorted - listGames[x].length + 1
                                listRanDomNumber = Array.from(Array(maxiumBallsSorted - minimumBallsSorted + 1), (_, i) => {
                                    return { number: minimumBallsSorted + i, active: false }
                                })
                            }
                            let item = {
                                id: Math.random().toString(16).slice(2),
                                title: x,
                                listRanDomNumber,
                                name,
                                price: itemGame[0].game.price,
                                isPariMutuelLotto: false,
                                icon: 'loto-turbo.png',
                            }
                            
                            dataItem.push(item)
                        })

                        dataItem = dataItem.sort((a, b) => {
                            return dataSourceOrder.indexOf(a.title) - dataSourceOrder.indexOf(b.title)
                        })
                        resp = [...resp, ...dataItem]
                    }
                }

                if (isShow645 || isShow545 || isShow459) {
                    const respPariMutuel = await GamesService.getListGames('pari-mutuel-lotto')
                    if (respPariMutuel.code === 200 && !isArrayEmpty(respPariMutuel.data)) {
                        const data645 = isShow645 ? respPariMutuel.data.filter((x) => x.game.slug.toLowerCase() === 'lotto-6-45') : []
                        const data545 = isShow545 ? respPariMutuel.data.filter((x) => x.game.slug.toLowerCase() === 'lotto-5-45') : []
                        const data459 = isShow459 ? respPariMutuel.data.filter((x) => x.game.slug.toLowerCase() === 'loto-4-59') : []

                        const dataPariMutuel = [...data645, ...data545, ...data459]
                        const dataItem = []

                        dataPariMutuel.forEach((x) => {
                            const number = x.game.config.setup['1'].picks
                            const maxPicks = x.game.config.setup['1'].maxPicks
                            const listRanDomNumber = [
                                {
                                    number,
                                    maxPicks,
                                },
                            ]

                            const item = {
                                id: Math.random().toString(16).slice(2),
                                title: x.game.name,
                                listRanDomNumber,
                                name: x.game.slug,
                                price: x.game.price,
                                isPariMutuelLotto: true,
                                icon: `${x.game.slug}.png`,
                                banner: x.game.slug,
                                jackpot: x.game.estimated_jackpot
                            }

                            dataItem.push(item)
                        })

                        resp = [...resp, ...dataItem]
                    }
                }
                this.dataSource = resp
                if (this.$router.history.current.name === 'Games-Loto') {
                    this.onChangeGame(this.$router.history.current.query?.loto)
                } else {
                    this.loadingPage = false
                }
            } catch (err) {
                this.loadingPage = false
                console.log(err, 'err')
            }
        },
        onChangeGame(value) {
            this.loadingPage = true
            if (value !== this.gameSelect) {
                this.gameSelect = value
                this.isMultiLotto = value === 'loto-5-90'


                this.dataShowGame = this.dataSource.filter((x) => (value === 'loto-5-90' ? !x.isPariMutuelLotto : x.name === this.getTranslatedText(value)))

                if (!isArrayEmpty(this.dataShowGame)) {
                    let param = {}
                    let configGames = localStorage.getItem('configGame')
                    if (!isStrEmpty(configGames)) {
                        param = JSON.parse(configGames)
                        localStorage.removeItem('configGame')
                    } else {
                        let item = this.dataShowGame[0]
                        param = {
                            title: item.title,
                            gameNumber: item.listRanDomNumber[0].number,
                            name: item.name,
                            price: item.price,
                            numberQp: item.title === 'turbo' ? 2 : item.listRanDomNumber[0].number,
                            listRanDomNumber: item.listRanDomNumber,
                            isPariMutuelLotto: item.isPariMutuelLotto,
                        }

                        if (item.isPariMutuelLotto) {
                            param['maxPicks'] = item.listRanDomNumber[0].maxPicks
                            param['numberQp'] = item.listRanDomNumber[0].number
                            param['jackpot'] = item.jackpot
                        }
                    }

                    this.isPariMutuelLotto = param.isPariMutuelLotto
                    this.dataGames = param
                    this.gameSlugActive = value
                    this.step = 2

                    let stepTabBetSlip = 1
                    if (this.getTranslatedText(value) === 'lotto-6-45') {
                        stepTabBetSlip = 2
                    } else if (this.getTranslatedText(value) === 'loto-4-59') {
                        stepTabBetSlip = 3
                    } else if (this.getTranslatedText(value) === 'lotto-5-45') {
                        stepTabBetSlip = 4
                    } else {
                        stepTabBetSlip = 1
                    }

                    const payload = {
                        step: stepTabBetSlip,
                    }
                    this.$store.dispatch('onSwitchBetslip', payload)
                } else {
                    this.step = 1
                }
                this.loadingPage = false
            } else {
                this.loadingPage = false
            }
        },
    },
}
