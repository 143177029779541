export const listMenugames = [
    {
        title: 'loto 5/90',
        icon: 'loto-turbo.png',
        step: 1,
    },
    {
        title: 'lotto 6 of 45',
        icon: 'loto-nap.png',
        step: 2,
    },
    {
        title: 'lotto 4/59',
        icon: 'loto-nap.png',
        step: 3,
    },
    {
        title: 'lotto 5/45',
        icon: 'loto-nap.png',
        step: 4,
    },
]
