<template>
    <div class="payout-detail__content table-loto645">
        <div class="table-loto645-title">{{ getTranslatedText('breakdown of winnings') }}</div>
        <table>
            <tr>
                <th class="num">{{ getTranslatedText('correct numbers') }}</th>
                <th class="earnings">{{ getTranslatedText('earnings') }}</th>
            </tr>
            <tr v-for="(value, index) in listLotoPayouts.loto645" :key="index">
                <td class="cell">
                    <span>{{ value.numbersN }}</span>
                </td>
                <td v-if="value.xargs !== ''">
                    <span>{{
                        getTranslatedText(value.earnings, {
                            currency: $store.state.CURRENCY,
                        })
                    }}</span>
                </td>
                <td v-else>
                    <span>{{ getTranslatedText(value.earnings) }}</span>
                </td>
            </tr>
        </table>
        <div class="table-note">
            <i> *à partager entre le nombre de gagnants </i>
        </div>
        <div class="table-loto645-title">{{ getTranslatedText('bumber of combinations per type of bet') }}</div>
        <table>
            <tr>
                <th class="type">{{ getTranslatedText('Type of bet') }}</th>
                <th class="numbers">{{ getTranslatedText('Nbre numbers') }}</th>
                <th class="combinations">{{ getTranslatedText('Number of combinations') }}</th>
                <th class="price">{{ getTranslatedText('Ticket price') }}</th>
            </tr>
            <tr v-for="(value, index) in listLotoPayouts.loto645Combinaisons" :key="index">
                <td class="cell">
                    <span>{{ value.type }}</span>
                </td>
                <td class="cell">
                    <span>{{ value.number }}</span>
                </td>
                <td class="cell">
                    <span>{{ value.combinaisons }}</span>
                </td>
                <td class="cell">
                    <span>{{ renderPrice(value.ticket) }} {{ $store.state.CURRENCY }}</span>
                </td>
            </tr>
        </table>
        <div class="table-list-notes">
            <span class="table-list-notes-text"> {{ getTranslatedText('Pari Simple : Sélection 6 numéros, 1 combinaison, mise 5 000 [currency]', { currency: $store.state.CURRENCY }) }}</span>
            <span class="table-list-notes-text"> {{ getTranslatedText('Multiple 7 : Sélection 7 numéros, 7 combinaisons,  mise 5 000 [currency] x 7 = 35 000 [currency2].', { currency: $store.state.CURRENCY, currency2: $store.state.CURRENCY }) }}</span>
            <span class="table-list-notes-text"> {{ getTranslatedText('Multiple 8 : Sélection 8 numéros, 28 combinaisons, mise 5 000 [currency] x 28 = 140 000 [currency2].', { currency: $store.state.CURRENCY, currency2: $store.state.CURRENCY }) }}</span>
            <span class="table-list-notes-text"> {{ getTranslatedText('Multiple 9 : Sélection 9 numéros, 84 combinaisons,  mise 5 000 [currency] x 84 = 420 000 [currency2].', { currency: $store.state.CURRENCY, currency2: $store.state.CURRENCY }) }}</span>
            <span class="table-list-notes-text"> {{ getTranslatedText('Multiple 10 : Sélection 10 numéros, 210 combinaisons,  mise  5 000 [currency] x 210 = 1 050 000 [currency2].', { currency: $store.state.CURRENCY, currency2: $store.state.CURRENCY }) }}</span>
        </div>
    </div>
</template>

<script>
import languageMixins from '@/mixins/language/language-mixins'
import listLotoPayouts from '@/resources/data/payouts.json'
import { formatPrice } from '@/utils/utils'

export default {
    mixins: [languageMixins],
    data() {
        return {
            listLotoPayouts,
        }
    },

    methods: {
        renderPrice(price) {
            let decimal = this.isPariMutuelLotto ? 2 : 0
            return formatPrice(price, decimal)
        },
    },
}
</script>
<style lang="scss">
@import '~ThemePath/scss/payouts/_payout-detail.scss';
</style>
