export const listMenuNavigation = [
    {
        title: '',
        path: '/',
        icon: 'home.png',
        step: '/',
    },
    {
        title: 'LOTO 5/90',
        path: '/games-loto?loto=loto-5-90',
        icon: 'loto-turbo.png',
        step: 1,
    },
    {
        title: 'LOTO 6/45',
        path: '/games-loto?loto=loto-6-45',
        icon: 'loto-nap.png',
        step: 2,
    },
    {
        title: 'LOTO 4/59',
        path: '/games-loto?loto=loto-4-59',
        icon: 'loto-nap.png',
        step: 3,
    },
    {
        title: 'LOTO 5/45',
        path: '/games-loto?loto=loto-5-45',
        icon: 'loto-nap.png',
        step: 4,
    },
    {
        title: 'HISTORY',
        path: '/history',
        icon: 'history.png',
        step: '/history',
    }, 
    {
        title: 'Results (Raffle)',
        path: '/raffle-results',
        icon: 'history.png',
        step: '/raffle-results',
    },
]
