import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import axios from 'axios'
import vuetify from '@/plugins/vuetify'
import i18n from './i18n/config'

Vue.config.productionTip = false
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: (h) => h(App),
}).$mount('#app')
