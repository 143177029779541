<template>
    <div class="events container-screen">
        <LoadingScreen v-if="loadingPage" />
        <template v-else>
            <div class="events__body">
                <TitleCommon :value="`${getTranslatedText(dataSourceGames.title)} ${dataSourceGames.isPariMutuelLotto ? '' : gameNumber}`" />
                <SelectFilter :isHiddenSelect="dataSourceGames.isPariMutuelLotto" :dataOption="dataSource" :jackpot="dataSourceGames.jackpot" nameFunction="on-filter-events" />
                <div class="events__content">
                    <div class="events__list">
                        <div class="events__item" :class="{ active: eventSelect.includes(v.event_id) }" v-for="(v, i) in dataSourceFilter" :key="i" @click="onSelectEvent(v.event_id, v)">
                            <span class="events__item-title" v-if="!dataSourceGames.isPariMutuelLotto">{{ v.draw_name }}</span>
                            <span class="events__item-date">{{ renderDate(v.draw_date) }}</span>
                        </div>
                    </div>
                    <div class="events_box-button">
                        <ButtonCommon v-if="!isHiddenButtonBack" type="active" :isNoHover="true" value="back" :isLoading="isLoadingButton" nameFunction="screen-game-on-handle-steps" :paramFunction="{ step: 1 }" />
                        <ButtonCommon type="error" :isNoHover="true" value="continue" :isLoading="isLoadingButton" nameFunction="on-events-continue" />
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { formatDate, isArrayEmpty, isSameDay, convertDate, isObjEmpty } from '@/utils/utils'

import GamesService from '@/services/games.service'
import pathAssetsMixins from '@/mixins/pathAssets/path-assets-mixins'
import languageMixins from '@/mixins/language/language-mixins'
import EventBus from '@/utils/EventBus'
import ButtonCommon from '@/components/common/button/index.vue'
import LoadingScreen from '@/components/common/loading/index.vue'
import TitleCommon from '@/components/common/title/index.vue'
import SelectFilter from '@/components/games/events/select/index.vue'

export default {
    mixins: [pathAssetsMixins, languageMixins],
    components: { ButtonCommon, SelectFilter, LoadingScreen, TitleCommon },
    props: {
        propsDataGames: {
            type: Object,
            default: () => {},
        },
        isHiddenButtonBack: {
            type: Boolean,
            default: false,
        },
        gameSlugActive: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            dataSource: [],
            dataSourceFilter: [],
            dataSourceGames: null,
            eventSelect: [],
            eventInfo: [],
            loadingPage: true,
            isLoadingButton: false,
            numberQp: 0,
            gameNumber: 0,
        }
    },
    watch: {
        propsDataGames() {
            this.fetchdata()
            this.eventSelect = []
        },
    },
    created() {
        this.fetchdata()
        EventBus.$on('on-filter-events', (param) => this.onFilterEvents(param))
        EventBus.$on('on-events-continue', () => this.onEventContinue())
        EventBus.$on('on-change-nav-games-2', (param) => {
            this.numberQp = param.numberQp
            this.gameNumber = param.gameNumber
        })
    },
    destroyed() {
        EventBus.$off('on-filter-events')
        EventBus.$off('on-events-continue')
        EventBus.$off('on-change-nav-games')
    },
    methods: {
        async fetchdata() {
            this.loadingPage = true
            try {
                let typeGame = this.propsDataGames.isPariMutuelLotto ? 'pari-mutuel-lotto' : 'multi-lotto'

                const resp = await GamesService.getListEvents(typeGame)
                if (resp.code === 200) {
                    let dataSource = resp.data

                    if(this.propsDataGames.isPariMutuelLotto) {
                        dataSource = dataSource.filter(x => x.game.toLowerCase() === this.propsDataGames.title.toLowerCase())
                    }

                    this.dataSource = dataSource
                    this.dataSourceFilter = dataSource
                    this.$store.dataListEvents = dataSource

                    this.dataSourceGames = this.propsDataGames
                    this.numberQp = this.propsDataGames.numberQp
                    this.gameNumber = this.propsDataGames.gameNumber

                    this.loadingPage = false
                }
            } catch (error) {
                this.loadingPage = false
                console.log(error)
            }
        },
        onSelectEvent(eventId, item) {
            const index = this.eventSelect.findIndex((x) => x === eventId)
            if (index !== -1) {
                this.eventSelect.splice(index, 1)
                this.eventInfo.splice(index, 1)
            } else {
                this.eventSelect.push(eventId)
                this.eventInfo.push({
                    name: item.draw_name,
                    date: item.draw_date,
                })
            }
        },
        renderDate(date) {
            const dateFormat = formatDate(convertDate(date))

            let valueDate = `${dateFormat.day}/${dateFormat.month}/${dateFormat.year} ${this.$store.state.isScreenMobile ? `${dateFormat.hours}:${dateFormat.minutes} ${dateFormat.convertHours}` : `${dateFormat.hour24h}H${dateFormat.minutes}`} `

            return valueDate
        },
        onFilterEvents(value) {
            let dataSourceFilter = [...this.dataSource]
            if (value !== 'all') {
                dataSourceFilter = this.dataSource.filter((x) => x.event_id === value)
            }
            this.dataSourceFilter = dataSourceFilter
        },
        onEventContinue() {
            if (isArrayEmpty(this.eventSelect)) {
                const param = {
                    text: 'Please choose the game',
                    type: 'warning',
                }
                EventBus.$emit('show-notications', param)

                return false
            } else {
                let param = {
                    step: 3,
                    number: {
                        game: this.dataSourceGames.name,
                        title: this.dataSourceGames.title,
                        gameNumber: this.gameNumber,
                        numberQp: this.numberQp,
                        event_id: this.eventSelect,
                        eventInfo: this.eventInfo,
                        selection: [],
                        draws: 1,
                        wager: this.dataSourceGames.price,
                        isPariMutuelLotto: this.dataSourceGames.isPariMutuelLotto,
                    },
                }

                if(!isObjEmpty(this.dataSourceGames.allowed_picks)) {
                    param.number['allowed_picks'] = this.dataSourceGames.allowed_picks
                }

                if (this.dataSourceGames.isPariMutuelLotto) param.number['maxPicks'] = this.dataSourceGames.maxPicks

                EventBus.$emit('screen-game-on-handle-steps', param)
            }
        },
    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/games/_event.scss';
</style>
