<template>
    <div class="menu-loto">
        <div class="menu-loto__content">
            <div class="menu-loto__content-title" :class="{ active: menuActive === 'play' }">{{ getTranslatedText('Play') }}</div>
            <ul class="menu-loto__content__menu">
                <li :class="{ active: menuItemActive === `play-${item.title}` }" v-for="(item, index) in dataMenu" :key="index" @click="redirectUrl(item, 'play')">
                    <img :src="require(`@/public/theme/${themePath}/img/menu/${item.icon}`)" />
                    <span :class="{ active: menuItemActive === `play-${item.title}` }">{{ getTranslatedText(`${item.isPariMutuelLotto ? '' : 'Loto '}${item.title}`) }}</span>
                </li>
            </ul>
        </div>
        <div class="menu-loto__content">
            <div class="menu-loto__content-title" :class="{ active: menuActive === 'results' }">{{ getTranslatedText('Results') }}</div>
            <ul class="menu-loto__content__menu">
                <li :class="{ active: menuActive === 'results' }" @click="redirectUrl(null, 'results')">
                    <img :src="require(`@/public/theme/${themePath}/img/menu/${isMultiLotto ? 'loto-turbo.png' : 'loto-nap.png'}`)" />
                    <span>{{ getTranslatedText(isMultiLotto ? 'Loto 5/90' : dataMenu[0]?.title || '') }}</span>
                </li>
            </ul>
        </div>
        <div class="menu-loto__content">
            <div class="menu-loto__content-title" :class="{ active: menuActive === 'payout' }">{{ getTranslatedText('Loto payouts') }}</div>
            <ul class="menu-loto__content__menu">
                <li :class="{ active: menuItemActive === `payout-${item.title}` }" v-for="(item, index) in dataMenu" :key="index" @click="redirectUrl(item, 'payout')">
                    <img :src="require(`@/public/theme/${themePath}/img/menu/${item.icon}`)" />
                    <span>{{ getTranslatedText(`${item.isPariMutuelLotto ? '' : 'Loto '}${item.title}`) }}</span>
                </li>
                <li v-if="!inActivePromotion.includes($route.query.loto)" :class="{ active: menuItemActive === 'promotion' }"  @click="gotoPromotion">
                    <img :src="require(`@/public/theme/${themePath}/img/menu/${iconDefault}`)" />
                    <span>{{ getTranslatedText('active promotion') }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import languageMixins from '@/mixins/language/language-mixins'
import themeMixins from '@/mixins/pathAssets/path-assets-mixins'
import EventBus from '@/utils/EventBus'

import { isArrayEmpty, isObjEmpty, isStrEmpty } from '@/utils/utils'

export default {
    mixins: [languageMixins, themeMixins],
    props: {
        dataMenu: {
            type: Array,
            default: () => [],
        },
        nameGame: {
            type: String,
            default: '',
        },
        isMultiLotto: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            menuActive: 'results',
            menuItemActive: '',
            iconDefault: 'loto-turbo.png',
            inActivePromotion: ['loto-6-45']
        }
    },
    watch: {
        dataMenu() {
            this.onChangeGame(this.dataMenu)
        },
    },
    created() {
        this.onChangeGame(this.dataMenu)
        EventBus.$on('on-change-game-screen-games', () => {
            this.onChangeGame()
        })
    },
    destroyed() {
        EventBus.$off('on-change-game-screen-games')
    },
    methods: {
        redirectUrl(item, type) {
            if (!isObjEmpty(item) && type === 'play') {
                let param = {
                    step: 2,
                    games: {
                        title: item.title,
                        gameNumber: item.listRanDomNumber[0].number,
                        name: item.name,
                        price: item.price,
                        numberQp: item.title === 'turbo' ? 2 : item.listRanDomNumber[0].number,
                        listRanDomNumber: item.listRanDomNumber,
                        isPariMutuelLotto: item.isPariMutuelLotto,
                        jackpot: item.jackpot,
                    },
                }

                if(!isObjEmpty(item.listRanDomNumber[0]?.allowed_picks)) {
                    param.games['allowed_picks'] = item.listRanDomNumber[0].allowed_picks
                }

                if (item.isPariMutuelLotto) {
                    param.games['maxPicks'] = item.listRanDomNumber[0].maxPicks
                    param.games['numberQp'] = item.listRanDomNumber[0].number
                }

                EventBus.$emit('screen-game-on-handle-steps', param)
            } else if (type === 'payout') {
                let param = {
                    step: 4,
                    title: item.title,
                    isPariMutuelLotto: item.isPariMutuelLotto,
                }
                EventBus.$emit('on-change-step-screen-loto', param)
            } else {
                let param = {
                    step: 1,
                }
                EventBus.$emit('on-change-step-screen-loto', param)
            }
            this.menuActive = type
            this.menuItemActive = !isStrEmpty(item?.title) ? `${type}-${item.title}` : ''
        },
        onChangeGame(dataSource) {
            if (!isArrayEmpty(dataSource)) {
                let item = this.dataMenu[0]
                this.menuActive = 'play'
                this.menuItemActive = !isStrEmpty(item?.title) ? `play-${!isStrEmpty(this.nameGame) ? this.nameGame : item.title}` : ''
                this.iconDefault = this.dataMenu[0].icon
            }
        },

        gotoPromotion() {
            let param = {
                step: 5,
            }
            EventBus.$emit('on-change-step-screen-loto', param)
            this.menuActive = 'payout'
            this.menuItemActive = 'promotion'
        }
    },
}
</script>
<style lang="scss">
@import '~ThemePath/scss/layouts/_menu-loto.scss';
</style>
