<template>
    <div v-if="!isLoadingPage" class="screen-number container-screen" :class="{ isPariMutuelLotto: this.propsDataSource.isPariMutuelLotto }">
        <TitleCommon :value="`${dataSource.title}${this.propsDataSource.isPariMutuelLotto ? '' : ` ${dataSource.gameNumber}`}`" />
        <div class="screen-number__box-btn-quick-pick">
            <ButtonCommon :isNoHover="true" value="Quick Pick" className="screen-number__btn-quick-pick" nameFunction="on-quick-pick-number" v-if="isCheckShowSelectPrice()"/>
            <template v-else>
                <h3 class="screen-number__box-btn-quick-pick-flex-title">{{ getTranslatedText('Quick selection') }}</h3>
                <div class="screen-number__box-btn-quick-pick-flex">
                    <template v-for="(item, key) in rangePickNumber" >
                        <ButtonCommon :key="key" :isNoHover="true" :value="item.toString()" className="screen-number__btn-quick-pick bgr-quick-pick" @on-click-button-common="onClickQuickPick(item)" />
                    </template>
                </div>
            </template>
        </div>
        <div class="screen-number__list">
            <div class="screen-number__item" :class="{ active: selection.includes(value.number) }" v-for="(value, index) in listNumber" :key="index" @click="() => onSelectNumber(value.number)">
                {{ value.number }}
            </div>
        </div>
        <div class="screen-number__value-number">
            <input v-model="valueSelection" class="screen-number__input" type="text" @blur="onBlurValueSelection" />
        </div>
        <div class="screen-number__footer">
            <div class="screen-number__footer-action-price">
                <div class="screen-number__info">
                    <span class="screen-number__text bold">
                        {{ getTranslatedText(`${dataSource.title}`) }}
                        <template v-if="isCheckShowSelectPrice()">{{ dataSource.gameNumber }}</template>
                    </span>
                    <span class="screen-number__text">{{ renderPrice(this.propsDataSource.isPariMutuelLotto ? priceTicketCombinations : dataSource.wager) }}</span>
                </div>
                <span v-if="isCheckShowSelectPrice()" class="screen-number__text wager">
                    <b>{{ getTranslatedText('wager') }}</b> ({{ getTranslatedText('min') }} {{ renderPrice(WARGER_MIN_ENV) }} - {{ getTranslatedText('max') }} {{ renderPrice(WARGER_MAX_ENV) }})
                </span>
                <input v-model="priceNumber" class="screen-number__input price" step="1" min="0" max="100" @blur="onBlurPriceNumber" v-on:keypress="onlyNumber" :disabled="!isCheckShowSelectPrice()" />
                <div v-if="isCheckShowSelectPrice()" class="screen-number__box-button">
                    <ButtonCommon :value="`+${renderPrice('1000')}`" :paramFunction="1000" nameFunction="on-change-price-number" />
                    <ButtonCommon :value="`+${renderPrice('5000')}`" :paramFunction="5000" nameFunction="on-change-price-number" />
                    <ButtonCommon :value="`+${renderPrice('10000')}`" :paramFunction="10000" nameFunction="on-change-price-number" />
                </div>
            </div>
            <div class="screen-number__footer-action">
                <div class="screen-number__info">
                    <span class="screen-number__text bold">{{ getTranslatedText('total') }}</span>
                    <span class="screen-number__text bold">{{ renderPrice(totalNumber) }}</span>
                </div>
                <div class="screen-number__box-button">
                    <ButtonCommon :isNoHover="true" :isLoading="$store.state.isLoadingAddCart" value="clear" nameFunction="on-show-dialog-clear" />
                    <ButtonCommon :isNoHover="true" :isLoading="$store.state.isLoadingAddCart" type="error" value="add to cart" nameFunction="on-add-to-cart" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import languageMixins from '@/mixins/language/language-mixins';
import EventBus from '@/utils/EventBus';
import ButtonCommon from '@/components/common/button/index.vue';
import LoadingScreen from '@/components/common/loading/index.vue';
import TitleCommon from '@/components/common/title/index.vue';
import { formatPrice, generateCombinations, isObjEmpty, isStrEmpty } from '@/utils/utils';

const WARGER_MIN_ENV = process.env.VUE_APP_WARGER_MIN || 2000;
const WARGER_MAX_ENV = process.env.VUE_APP_WARGER_MAX || 50000;

export default {
    mixins: [languageMixins],
    components: { ButtonCommon, LoadingScreen, TitleCommon },
    props: {
        propsDataSource: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            isLoadingPage: true,
            dataSource: null,
            listNumber: [],
            selection: [],
            rangePickNumber: [],
            valueSelection: '',
            priceNumber: 0,
            totalNumber: 0,
            defaultWager: 0,
            priceTicketCombinations: 0,
            WARGER_MIN_ENV,
            WARGER_MAX_ENV,
        };
    },
    created() {
        this.fetchdata();
        EventBus.$on('on-change-price-number', (param) => this.onChangePriceNumber(param));
        EventBus.$on('on-quick-pick-number', () => this.onClickQuickPick());
        EventBus.$on('on-clear-select-number', () => this.onClearSelectNumber());
        EventBus.$on('on-show-dialog-clear', () => this.onShowConfirmClear());
        EventBus.$on('on-add-to-cart', () => this.onAddToCart());
        EventBus.$on('on-change-nav-games-3', (param) => {
            this.dataSource.numberQp = param.numberQp;
            this.dataSource.gameNumber = param.gameNumber;
            this.onClearSelectNumber();
        });
    },
    watch: {
        selection() {
            this.renderValueNumber();
        },
        priceNumber() {
            this.totalNumber = parseInt(this.priceNumber.replaceAll(',', '')) * this.propsDataSource.event_id.length;
            this.dataSource.wager = parseInt(this.priceNumber.replaceAll(',', ''));
        },
    },
    destroyed() {
        EventBus.$off('on-change-price-number');
        EventBus.$off('on-quick-pick-number');
        EventBus.$off('on-clear-select-number');
        EventBus.$off('on-show-dialog-clear');
        EventBus.$off('on-add-to-cart');
        EventBus.$off('on-change-nav-games');
    },
    methods: {
        fetchdata() {
            this.dataSource = this.propsDataSource;
            this.priceNumber = this.renderPrice(parseInt(this.propsDataSource.wager));
            this.defaultWager = parseInt(this.propsDataSource.wager);
            this.priceTicketCombinations = parseInt(this.propsDataSource.wager);
            this.listNumber = Array.from(Array(this.quantityNumberSelect()), (_, i) => {
                return { number: ++i, selected: false };
            });
            const allowed_picks = this.propsDataSource?.allowed_picks || {}
            this.rangePickNumber = !isObjEmpty(allowed_picks) ? Array.from({ length: allowed_picks.max - allowed_picks.min + 1 }, (_, index) => index + allowed_picks.min) : []
            this.isLoadingPage = false;
        },

        renderTextTickets(arr) {
            let valueNumberSelectRender = '';
            arr.forEach((x, index) => {
                valueNumberSelectRender = `${valueNumberSelectRender}${index !== 0 ? ',' : ''} ${x}`;
            });
            return valueNumberSelectRender;
        },

        // action screen
        onClickQuickPick(number) {
            let numberQp = number || this.dataSource.numberQp

            let numbers = Array(this.quantityNumberSelect())
                .fill(0)
                .map((_, index) => index + 1);
            numbers.sort(() => Math.random() - 0.5);
            numbers = [...numbers.slice(0, numberQp)];
            this.selection = [...numbers];
        },

        onShowConfirmClear() {
            const param = {
                description: 'Are you sure you want to delete?',
                nameFunction: 'on-clear-select-number',
            };
            EventBus.$emit('on-show-dialog', param);
        },

        onClearSelectNumber() {
            this.priceNumber = this.renderPrice(parseInt(this.defaultWager));
            this.selection = [];
        },

        onAddToCart() {
            this.$store.state.isLoadingAddCart = true;
            let numberQp = this.dataSource.numberQp;
            let text = this.getTranslatedText(`Please make sure that you select [number] of balls!`, {
                number: numberQp,
            });
            if (!isObjEmpty(this.dataSource.allowed_picks)) {
                numberQp = this.dataSource.allowed_picks.min;
                text = this.getTranslatedText(`Please make sure that you select from [min] to [max] of balls!`, {
                    min: this.dataSource.allowed_picks?.min || 2,
                    max: this.dataSource.allowed_picks?.max || 5,
                });
            }

            if (numberQp > this.selection.length) {
                const param = {
                    text,
                    type: 'warning',
                };
                EventBus.$emit('show-notications', param);
                this.$store.state.isLoadingAddCart = false;

                return false;
            }

            const param = this.dataSource;
            param['selection'] = this.selection;
            param['date'] = this.selection;
            param['wager'] = parseInt(this.priceNumber.replaceAll(',', ''));
            param['id'] = new Date().getTime().toString(36).concat(performance.now().toString(), Math.random().toString()).replace(/\./g, '');
            this.$store.dispatch('onAddToCart', param);
            this.onClearSelectNumber();
        },

        // select number
        renderValueNumber() {
            const selection = [...this.selection];
            const numberSelect = selection.sort((a, b) => a - b);

            let numberQp = this.dataSource.numberQp;

            if (!isObjEmpty(this.dataSource.allowed_picks)) {
                numberQp = this.dataSource.allowed_picks.max;
            }

            numberQp = parseInt(numberQp);

            if (selection.length > numberQp) {
                let combinations = generateCombinations(selection.length, numberQp);
                let priceTicketCombinations = parseFloat(this.priceNumber.replaceAll(',', '')) * combinations;
                this.priceTicketCombinations = priceTicketCombinations;
                this.totalNumber = priceTicketCombinations * this.propsDataSource.event_id.length;
            } else {
                let priceTicketCombinations = parseInt(this.propsDataSource.wager);
                this.priceTicketCombinations = priceTicketCombinations;
                this.totalNumber = priceTicketCombinations * this.propsDataSource.event_id.length;
            }

            let valueNumberSelectClone = this.renderTextTickets(numberSelect);

            this.valueSelection = valueNumberSelectClone;
        },

        onSelectNumber(number) {
            const selection = this.selection;
            const index = selection.findIndex((x) => x === number);
            if (index !== -1) {
                selection.splice(index, 1);
            } else {
                if (this.maxLengthQuickPick() > this.selection.length) {
                    selection.push(number);
                }
            }

            this.selection = [...selection];
        },

        onBlurValueSelection() {
            let inputArray = this.valueSelection
                .split(',')
                .map((num) => Number(num.trim()))
                .filter((num, index, self) => num >= 1 && num <= this.quantityNumberSelect() && index === self.indexOf(num));

            inputArray = inputArray.slice(0, this.maxLengthQuickPick());
            this.valueSelection = inputArray.join(',');
            this.selection = [...inputArray];
        },

        // change price
        onBlurPriceNumber() {
            let price = !isStrEmpty(this.priceNumber) ? this.priceNumber.replaceAll(',', '') : 0;
            let value = parseFloat(price) || 0;
            if (value < this.WARGER_MIN_ENV) value = this.WARGER_MIN_ENV;
            if (value > this.WARGER_MAX_ENV) value = this.WARGER_MAX_ENV;
            this.priceNumber = this.renderPrice(value);
        },

        onChangePriceNumber(value) {
            let price = value || 0;
            let priceMore = parseInt(this.priceNumber.replaceAll(',', '')) + price;
            if (priceMore > WARGER_MAX_ENV) priceMore = WARGER_MAX_ENV;
            this.priceNumber = this.renderPrice(priceMore);
        },

        onlyNumber(event) {
            if (event.key === '-' && event.target.selectionStart !== 0) {
                event.preventDefault();
                return;
            }
            if (event.keyCode < 48 || event.keyCode > 57) {
                event.preventDefault();
            }
        },

        // uti render
        renderPrice(price) {
            return formatPrice(price);
        },

        // on change length game
        quantityNumberSelect() {
            let number = 45;

            if (this.propsDataSource.game === 'lotto-5-45') {
                number = 45;
            } else if (this.propsDataSource.game === 'lotto-6-45') {
                number = 45;
            } else if (this.propsDataSource.game === 'loto-4-59') {
                number = 59;
            } else {
                number = 90;
            }
            return number;
        },

        maxLengthQuickPick() {
            let numberQp = this.dataSource.numberQp;

            if (!isObjEmpty(this.dataSource.allowed_picks)) {
                numberQp = this.dataSource.allowed_picks.max;
            }

            let maxLength = this.propsDataSource.isPariMutuelLotto ? this.propsDataSource.maxPicks : numberQp;
            return maxLength;
        },


        isCheckShowSelectPrice() {
            if(this.propsDataSource?.allowed_picks) {
                return false
            }
            if(this.propsDataSource.isPariMutuelLotto) {
                return false
            }
            return true
        }
    },
};
</script>

<style lang="scss">
@import '~ThemePath/scss/games/_number.scss';
</style>
