import axios from 'axios'
import axiosLib from 'axios'

const axiosAuth = axiosLib.create()

export function setToken(data) {
    localStorage.setItem('token', data)
}

export function getToken() {
    return localStorage.getItem('token')
}

export function deleteToken() {
    localStorage.removeItem('token')
}

export const getLocale = () => {
    return localStorage.getItem('locale') || 'en'
}

export function setAccount(data) {
    return localStorage.setItem('account', data)
}

export function getAccount() {
    return localStorage.getItem('account')
}

export function deleteAccount(data) {
    return localStorage.removeItem('account')
}

export function setCustomerId(data) {
    return localStorage.setItem('customerId', data)
}

export function getCustomerId() {
    return localStorage.getItem('customerId')
}

export function setCurrency(data) {
    return localStorage.setItem('currency', data)
}

export function getCurrency() {
    return localStorage.getItem('currency')
}

export function deleteCurrency() {
    return localStorage.removeItem('currency')
}

export function setexternalIdType(data) {
    return localStorage.setItem('externalIdType', data)
}

export function getexternalIdType() {
    return localStorage.getItem('externalIdType')
}

export function deleteExternalIdType() {
    return localStorage.removeItem('externalIdType')
}

export function setLang(data) {
    return localStorage.setItem('lang', data)
}

export function getLang() {
    return localStorage.getItem('lang')
}


export function setGame(data) {
    return localStorage.setItem('gameShow', data)
}

export function getGame() {
    return localStorage.getItem('gameShow')
}

export function deleteGame() {
    return localStorage.removeItem('gameShow')
}

export function setAccount645(data) {
    return localStorage.setItem('account_645', data)
}

export function getAccount645() {
    return localStorage.getItem('account_645')
}

export function deleteAccount645() {
    return localStorage.removeItem('account_645')
}

axiosAuth.interceptors.request.use((request) => {
    // const token = getToken()
    // if (token) {
    //     request.headers.Authorization = token
    // }
    
    request.headers.ContentType = 'application/json'
    request.headers.locale = getLocale()

    return request
})

axiosAuth.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        if (error.response && error.response.data) {
            return Promise.reject(error.response.data)
        }
        return Promise.reject(error.message)
    }
)

export default axiosAuth
